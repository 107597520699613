import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconPlusCircleFill: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase viewBox="0 0 20 20" {...props}>
    <Path
      d="M10 17.5C5.85775 17.5 2.5 14.1422 2.5 10C2.5 5.85775 5.85775 2.5 10 2.5C14.1422 2.5 17.5 5.85775 17.5 10C17.5 14.1422 14.1422 17.5 10 17.5ZM9.25 9.25H6.25V10.75H9.25V13.75H10.75V10.75H13.75V9.25H10.75V6.25H9.25V9.25Z"
      fill="currentColor"
    />
  </IconBase>
);
