import React, { useEffect, useRef } from 'react';
import { FleetViewDevice } from '../../../../state/fleetView/types';
import styles from './FleetViewPageDetails.module.scss';
import { Accordion } from '../../../../aurora/components/Accordion/Accordion';
import FleetViewShipperCard from './FleetViewShipperCard';
import DetailsHeader from './DetailsHeader';
import { RootState } from '../../../../state/store';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../../../components/layout/LoadingSpinner';
import TableError from '../../../../components/table/TableError';
import { setSelectedSerialNumber } from '../../../../state/fleetView/';
import { showToast } from '../../../../aurora/components/Toast/Toast';

interface FleetViewPageDetailsProps {
  devices: FleetViewDevice[];
  isLoading: boolean;
  error: Error | undefined;
}

const FleetViewPageDetails: React.FC<FleetViewPageDetailsProps> = ({
  devices,
  isLoading,
  error,
}) => {
  const dispatch = useDispatch();
  const selectedSerialNumber = useSelector(
    (state: RootState) => state.fleetView.selectedSerialNumber
  );

  /* The use of a ref for selectedSerialNumber helps avoid stale state issues.
   When a new card is opened, the accordion toggle handler is called twice:
   once for opening the new card and once for closing the previous card.
   Using a ref ensures that the state is not incorrectly set to null when
   the previous card is closed, as the ref maintains the current serial number.*/

  const selectedSerialNumberRef = useRef<string | null>(selectedSerialNumber);

  const detailsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedSerialNumber !== null && detailsContainerRef.current) {
      const selectedIndex = devices.findIndex(
        (device) => device.serialNumber === selectedSerialNumber
      );

      if (selectedIndex !== -1) {
        const element = detailsContainerRef.current.querySelector(
          `[data-accordion-id="accordion-${selectedIndex}"]`
        );

        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  }, [selectedSerialNumber, devices]);

  const handleAccordionToggle = (idx: number, isOpen: boolean) => {
    const currentDevice = devices[idx];

    if (isOpen) {
      selectedSerialNumberRef.current = currentDevice.serialNumber;
      dispatch(setSelectedSerialNumber(currentDevice.serialNumber));

      if (!currentDevice.lastKnownLocation) {
        showToast({
          type: 'info',
          title: 'No Location Data',
          text: `Shipper ${currentDevice.serialNumber} has no location data to display`,
        });
      }
    } else if (selectedSerialNumberRef.current === currentDevice.serialNumber) {
      selectedSerialNumberRef.current = null;
      dispatch(setSelectedSerialNumber(null));
    }
  };

  if (isLoading) {
    return (
      <div className={styles.detailsContainer}>
        <DetailsHeader />
        <div className={styles.loadingContainer}>
          <LoadingSpinner width={64} height={64} />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.detailsContainer}>
        <DetailsHeader />
        <div className={styles.accordionContainer}>
          <TableError />
        </div>
      </div>
    );
  }

  const sections = devices.map((device) => {
    const { header, body } = FleetViewShipperCard({ device });
    return { header, body };
  });

  const selectedIndex = selectedSerialNumber
    ? devices.findIndex(
        (device) => device.serialNumber === selectedSerialNumber
      )
    : undefined;

  return (
    <div className={styles.detailsContainer}>
      <DetailsHeader />
      <div className={styles.accordionContainer} ref={detailsContainerRef}>
        <Accordion
          sections={sections}
          panelClassName={styles.accordionPanel}
          onAccordionToggle={handleAccordionToggle}
          className={styles.accordion}
          initialOpenIdx={selectedIndex}
        />
      </div>
    </div>
  );
};

export default FleetViewPageDetails;
