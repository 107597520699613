import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import OrderSearch from './OrderSearch';
import MobileCompanyFilter from '../../../../components/filters/MobileChecklistFilter/MobileCompanyFilter';
import {
  setCompanyIdsFilter,
  setCreatedDateFilter,
  setExpectedDeliveryDateFilter,
  setFulfilledDateFilter,
} from '../../../../state/orders';
import CompanyFilter from '../../../../components/filters/CompanyFilter/CompanyFilter';
import styles from '../../../page.module.scss';
import OrderDateFilter from './OrderDateFilter';
import OrderStatusSelect from './OrderStatusSelect';

const OrdersTopActions = () => {
  const dispatch = useDispatch();
  const { companyIds, createdAt, expectedDeliveryDate, fulfilledAt } =
    useSelector((state: RootState) => state.orders.selectedFilters || {});

  return (
    <>
      <div className={styles.filterGroup}>
        <OrderStatusSelect />
        <OrderDateFilter
          dateRange={createdAt}
          placeholder="Submission Date"
          action={setCreatedDateFilter}
        />
        <OrderDateFilter
          dateRange={expectedDeliveryDate}
          placeholder="Delivery Date"
          action={setExpectedDeliveryDateFilter}
          maxDate={null}
        />
        <OrderDateFilter
          dateRange={fulfilledAt}
          placeholder="Fulfillment Date"
          action={setFulfilledDateFilter}
        />
        <CompanyFilter
          setCompanyIdsAction={(companyIds) =>
            dispatch(setCompanyIdsFilter(companyIds))
          }
          filteredCompanyIds={companyIds}
        />
        <MobileCompanyFilter
          setCompanyIdsAction={(companyIds) =>
            dispatch(setCompanyIdsFilter(companyIds))
          }
          filteredCompanyIds={companyIds}
        />
      </div>
      <OrderSearch />
    </>
  );
};

export default OrdersTopActions;
