import React, { RefObject } from 'react';
import { useDispatch } from 'react-redux';
import { Label } from '../../../../../aurora/typography/Label/Label';
import { EventEntity } from '../../../../../state/events/types';
import { setHoveredSnapshot } from '../../../../../state/journeys';
import { SegmentSnapshotEntity } from '../../../../../state/segmentSnapshots/types';
import EventsSection from '../../../../events/components/slideout/EventsSection';
import EventStatusBadge from '../../EventStatusBadge';
import InfoCard from '../InfoCard';
import styles from './EventInfoCard.module.scss';

interface Props {
  events: EventEntity[];
  eventsCardRef: RefObject<HTMLDivElement>;
  viewEvents: boolean;
  setViewEvents: (value: boolean) => void;
  snapshots: SegmentSnapshotEntity[];
}
const EventInfoCard = ({
  events,
  eventsCardRef,
  viewEvents,
  setViewEvents,
  snapshots,
}: Props) => {
  const dispatch = useDispatch();

  if (events.length === 0) {
    return null;
  }

  const title = (
    <div className={styles.title}>
      <Label size="sm" color="strong900">
        Events
      </Label>
      <EventStatusBadge totalEvents={events.length} />
    </div>
  );

  // hover start of the event
  const onViewStart = (event: EventEntity) => {
    const startIdx = snapshots.findIndex(
      (s) => new Date(s.timestamp) >= new Date(event.eventStart)
    );

    dispatch(
      setHoveredSnapshot({
        hovered:
          startIdx === undefined
            ? null
            : {
                snapshot: snapshots[startIdx],
                trigger: 'event',
                index: startIdx,
              },
      })
    );
  };

  return (
    <InfoCard
      ref={eventsCardRef}
      title={title}
      accordion
      showOpen={viewEvents}
      onAccordionToggle={(idx, open) => {
        if (!open) {
          setViewEvents(false);
        }
      }}
    >
      <EventsSection events={events} onViewStart={onViewStart} />
    </InfoCard>
  );
};

export default EventInfoCard;
