import React, { useEffect, useState } from 'react';
import { SegmentEntity } from '../../../../state/segments/types';
import SegmentsApi from '../../../../api/segmentsApi';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../../../../state/auth';
import {
  ButtonGroup,
  ButtonProps,
} from '../../../../aurora/components/ButtonGroup/ButtonGroup';
import { EventEntity } from '../../../../state/events/types';
import {
  selectSnapshotsBySegment,
  setSnapshots,
  SegmentToSnapshotsMap,
} from '../../../../state/segments';
import styles from './SnapshotsGraphContainer.module.scss';
import SnapshotsGraph, {
  ExternalTempData,
} from '../../../journeys/components/journeyPage/graph/SnapshotsGraph';
import ArtycPanel from '../../../journeys/components/journeyPage/graph/ArtycPanel';

// TODO: this code still assumes there could be multiple segments when there's only one
// can be cleaned up
interface Props {
  event: EventEntity;
  onLoaded?: () => void;
  printVersion?: boolean;
  isArtycViewer: boolean;
}
const SnapshotsGraphContainer = ({
  event,
  onLoaded,
  printVersion,
  isArtycViewer,
}: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const snapshotsBySegment = useSelector(selectSnapshotsBySegment);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadedSegments, setLoadedSegments] = useState<SegmentEntity[]>([]);
  const [tempUnit, setTempUnit] = useState<'C' | 'F'>('C');

  const [tempThresholdsC, setTempThresholdsC] = useState<number[]>([]);
  const [minTempAxisC, setMinTempAxisC] = useState<number | undefined>(
    undefined
  );
  const [maxTempAxisC, setMaxTempAxisC] = useState<number | undefined>(
    undefined
  );
  const [showDateAsDuration, setShowDateAsDuration] = useState<boolean>(true);
  const [showLidOpen, setShowLidOpen] = useState<boolean>(false);
  const [externalTempData, setExternalTempData] = useState<ExternalTempData[]>(
    []
  );

  const loadSegments = async () => {
    try {
      setLoading(true);
      setError(false);

      const segmentResp = await SegmentsApi.getSegment(
        axiosPrivate,
        auth,
        event.segment
      );
      setLoadedSegments([segmentResp]);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const loadSnapshots = async () => {
    try {
      setLoading(true);
      setError(false);

      for (let segment of loadedSegments) {
        if (snapshotsBySegment[segment._id] === undefined) {
          const snapshots = await SegmentsApi.getSegmentSnapshots(
            axiosPrivate,
            auth,
            isArtycViewer,
            segment._id
          );
          dispatch(setSnapshots({ [segment._id]: snapshots }));
        }
      }
      onLoaded && onLoaded();
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSegments();
  }, []);

  useEffect(() => {
    if (Object.keys(loadedSegments).length > 0) {
      loadSnapshots();
    }
  }, [loadedSegments]);

  if (loading) {
    return <div className={styles.container}>Loading...</div>;
  }

  if (error || loadedSegments.length === 0) {
    return (
      <div className={styles.container}>
        Error -- please close and open again
      </div>
    );
  }

  const hasSegment =
    loadedSegments.length > 0 &&
    loadedSegments.some(
      (segment) => snapshotsBySegment[segment._id] !== undefined
    );

  if (!hasSegment) {
    return <div className={styles.container}>No data found</div>;
  }

  const loadedSnapshotsBySegment: SegmentToSnapshotsMap = loadedSegments.reduce(
    (acc, segment) => {
      if (snapshotsBySegment[segment._id]) {
        acc[segment._id] = snapshotsBySegment[segment._id];
      }
      return acc;
    },
    {} as SegmentToSnapshotsMap
  );

  const graph = (
    <SnapshotsGraph
      snapshots={loadedSnapshotsBySegment[loadedSegments[0]._id]}
      segment={loadedSegments[0]}
      externalTempData={externalTempData}
      tempUnit={tempUnit}
      isArtycViewer={isArtycViewer}
      tempThresholdsC={tempThresholdsC}
      minTempAxisC={minTempAxisC}
      maxTempAxisC={maxTempAxisC}
      showLidOpen={showLidOpen}
      showDateAsDuration={showDateAsDuration}
    />
  );

  const temps: ButtonProps[] = [
    {
      label: '°C',
      onClick: () => setTempUnit('C'),
    },
    {
      label: '°F',
      onClick: () => setTempUnit('F'),
    },
  ];

  return (
    <div className={styles.container}>
      {!printVersion ? (
        <ArtycPanel
          isArtycViewer={isArtycViewer}
          segments={loadedSegments}
          tempThresholdsC={tempThresholdsC}
          setTempThresholdsC={setTempThresholdsC}
          setMinTempAxisC={setMinTempAxisC}
          setMaxTempAxisC={setMaxTempAxisC}
          showDateAsDuration={showDateAsDuration}
          setShowDateAsDuration={setShowDateAsDuration}
          showLidOpen={showLidOpen}
          setShowLidOpen={setShowLidOpen}
          tempUnit={tempUnit}
          setExternalTempData={setExternalTempData}
        />
      ) : null}
      <div className={styles.graphPanel}>
        {printVersion ? null : (
          <div className={styles.graphButtons}>
            <ButtonGroup buttonProps={temps} initialActiveIdx={0} />
          </div>
        )}
        {graph}
      </div>
    </div>
  );
};

export default SnapshotsGraphContainer;
