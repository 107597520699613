import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  LoadDataAction,
  EntityState,
  DataPayload,
  SortPayloadAction,
} from '../types';
import { AxiosInstance } from 'axios';
import { structuredFetch } from '../../api/api';
import { Auth } from '../auth/types';
import { OrderEntity, OrderFilters, OrderStatus } from './types';
import OrdersApi from '../../api/ordersApi';
import { showToast } from '../../aurora/components/Toast/Toast';

export interface OrderState extends EntityState<OrderEntity> {
  selectedFilters?: OrderFilters;
}

const initialState: OrderState = {
  data: [],
  currentPage: 0,
  resultsPerPage: 8,
  totalPages: 0,
  sortColumn: 'createdAt',
  sortDirection: -1,
  selectedFilters: {},
};

interface UpdateStatusPayload {
  axios: AxiosInstance;
  auth: Auth | null;
  orderId: string;
  status: OrderStatus;
}
export const markInProgressAction = createAsyncThunk(
  'events/markInProgress',
  ({ axios, auth, orderId, status }: UpdateStatusPayload) => {
    return OrdersApi.updateOrderStatus(axios, auth, orderId, status);
  }
);

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders: (
      state,
      { payload }: PayloadAction<DataPayload<OrderEntity>>
    ) => {
      state.data = payload.entities;
      state.totalPages = payload.totalPages;
    },
    sortOrders: (state, { payload }: SortPayloadAction<OrderEntity>) => {
      state.sortColumn = payload.column;
      state.sortDirection = payload.direction;
    },
    setCompanyIdsFilter: (
      state,
      { payload }: PayloadAction<string[] | undefined>
    ) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        companyIds: payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setFulfilledDateFilter: (
      state,
      { payload }: PayloadAction<[string | undefined, string | undefined]>
    ) => {
      const [startDate, endDate] = payload;
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        fulfilledAt: {
          startDate,
          endDate,
        },
      });
      state.currentPage = initialState.currentPage;
    },
    setExpectedDeliveryDateFilter: (
      state,
      { payload }: PayloadAction<[string | undefined, string | undefined]>
    ) => {
      const [startDate, endDate] = payload;
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        expectedDeliveryDate: {
          startDate,
          endDate,
        },
      });
      state.currentPage = initialState.currentPage;
    },
    setCreatedDateFilter: (
      state,
      { payload }: PayloadAction<[string | undefined, string | undefined]>
    ) => {
      const [startDate, endDate] = payload;
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        createdAt: {
          startDate,
          endDate,
        },
      });
      state.currentPage = initialState.currentPage;
    },
    setStatusFilter: (
      state,
      { payload }: PayloadAction<OrderStatus[] | undefined>
    ) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        status: payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setSearchFilter: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.selectedFilters = Object.assign({}, state.selectedFilters, {
        searchQuery: payload,
      });
      state.currentPage = initialState.currentPage;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload;
    },
    resetPage: (state) => {
      state.selectedFilters = initialState.selectedFilters;
      state.sortColumn = initialState.sortColumn;
      state.sortDirection = initialState.sortDirection;
      state.currentPage = initialState.currentPage;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(markInProgressAction.pending, () => {
      showToast({
        type: 'loading',
        title: 'Updating',
        text: 'Updating your order. Please wait...',
      });
    });

    builder.addCase(markInProgressAction.fulfilled, (state, action) => {
      const updatedOrder = action.meta.arg.orderId;
      const updatedData = state.data.map((order) => {
        if (updatedOrder === order._id) {
          return Object.assign({}, order, {
            status: OrderStatus.IN_PROGRESS,
          });
        }
        return order;
      });
      state.data = updatedData;
      showToast({
        type: 'success',
        title: 'Success!',
        text: 'Order status updated!',
      });
    });

    builder.addCase(markInProgressAction.rejected, () => {
      showToast({
        type: 'error',
        title: 'Un oh...',
        text: 'There was an error updating your order. \nPlease try again or contact support if it continues.',
      });
    });
  },
});

export const loadOrders: LoadDataAction<OrderEntity> = async (
  axios: AxiosInstance,
  auth: Auth,
  state: EntityState<OrderEntity>
) => {
  const {
    resultsPerPage,
    currentPage,
    sortColumn,
    sortDirection,
    selectedFilters,
  } = state;

  const { entities, totalPages } = await structuredFetch<OrderEntity>(
    axios,
    auth,
    'orders',
    resultsPerPage,
    currentPage,
    sortColumn,
    sortDirection,
    selectedFilters
  );

  return setOrders({ entities, totalPages });
};

export const {
  setOrders,
  sortOrders,
  setCompanyIdsFilter,
  setFulfilledDateFilter,
  setExpectedDeliveryDateFilter,
  setCreatedDateFilter,
  setStatusFilter,
  setSearchFilter,
  setCurrentPage,
  resetPage,
} = orderSlice.actions;

export default orderSlice.reducer;
