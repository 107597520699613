import React from 'react';
import SelectField from '../../../../components/forms/SelectField';
import formStyles from '../../../../components/forms/Form.module.scss';
import { SelectOptionType } from '../../../../aurora/components/Select/Select';
import AddressSection from './AddressSection';
import ExpectedDeliveryDate from './ExpectedDeliveryDate';
import { FieldArray } from 'react-final-form-arrays';
import { required } from '../../../../components/forms/validators';

// TODO(order): remove fulfilling prop once we've moved EditOrderButton to CaasOrderForm
interface Props {
  isArtycViewer: boolean;
  shipperOptions: SelectOptionType[];
  fulfilling: boolean;
}
const FulfillOrderForm = ({
  isArtycViewer,
  shipperOptions,
  fulfilling,
}: Props) => {
  return (
    <div className={formStyles.form}>
      <div className={formStyles.formRow}>
        {isArtycViewer ? (
          <>
            <SelectField
              name="device"
              options={shipperOptions}
              label="Shipper"
              showOptional={!fulfilling}
              validate={fulfilling ? required : undefined}
              clearable
            />
          </>
        ) : null}
        {fulfilling ? null : (
          <ExpectedDeliveryDate isArtycViewer={isArtycViewer} />
        )}
      </div>
      <FieldArray name="shipments">
        {({ fields }) => (
          <>
            {fields.map((name, index) => (
              // for now assume the first leg is user and second leg is lab
              <AddressSection
                key={name}
                heading={
                  index === 0 ? 'Leg 1: User Address' : 'Leg 2: Lab Address'
                }
                isArtycViewer={isArtycViewer}
                shipmentPrefix={name}
                isLab={index === 1}
                addressAccordion={isArtycViewer}
                includeTracking
                trackingRequired={fulfilling}
              />
            ))}
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default FulfillOrderForm;
