import React from 'react';
import { FleetViewDevice } from '../../../../state/fleetView/types';
import styles from './FleetViewShipperCard.module.scss';
import {
  IconTemperature,
  IconBattery,
  IconWarningCircleFill,
  IconMapPin,
} from '../../../../aurora/icons';
import { Text } from '../../../../aurora/typography/Text/Text';
import DeploymentStatusBadge from '../DeploymentStatusBadge';
import { formatDateWithTime } from '../../../../utils/dateUtil';

interface ShipperCardProps {
  device: FleetViewDevice;
}

const FleetViewShipperCard = ({ device }: ShipperCardProps) => {
  const hasExcursions =
    device.temperatureExcursions && device.temperatureExcursions.length > 0;
  const header = (
    <div className={styles.headerRow}>
      <div
        className={`${styles.headerSerialNumber} ${
          hasExcursions ? styles.warning : ''
        }`}
      >
        {hasExcursions && <IconWarningCircleFill width={15} height={15} />}
        {`#${device.serialNumber}`}
      </div>

      <div className={styles.statusBadgeContainer}>
        <DeploymentStatusBadge
          deploymentStatus={device.deviceDeploymentStatus}
        />
      </div>
    </div>
  );

  const tags = (
    <div className={styles.tagContainer}>
      {device.lastKnownSensorData && (
        <div className={styles.tagGroup}>
          <div className={styles.tagRow}>
            <div className={styles.tag}>
              <IconTemperature width={16} height={16} />
              <div
                className={styles.tagText}
              >{`${device.lastKnownSensorData?.tempC.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              })}°C`}</div>
            </div>
            <div className={styles.tag}>
              <IconBattery width={16} height={16} />
              <div className={styles.tagText}>{`${Math.round(
                device.lastKnownSensorData?.battery
              )}%`}</div>
            </div>
          </div>
          <div className={styles.tagLastUpdated}>
            {`Last Updated: ${formatDateWithTime(
              new Date(device.lastKnownSensorData?.timestamp)
            )}`}
          </div>
        </div>
      )}
      {device.lastKnownLocation && (
        <div className={styles.tagGroup}>
          <div className={styles.tagRow}>
            <div className={styles.tag}>
              <IconMapPin width={16} height={16} />
              <div
                className={styles.tagText}
              >{`LAT: ${device.lastKnownLocation.lat.toFixed(4)},
             LONG: ${device.lastKnownLocation.long.toFixed(4)}`}</div>
            </div>
          </div>
          {device.lastKnownLocation?.timestamp !==
            device.lastKnownSensorData?.timestamp && (
            <div className={styles.tagLastUpdated}>
              {`Last Updated: ${formatDateWithTime(
                new Date(device.lastKnownLocation?.timestamp)
              )}`}
            </div>
          )}
        </div>
      )}
    </div>
  );

  const body = (
    <div className={styles.content}>
      <div className={styles.body}>
        <Text size="sm" color="sub600">
          {device.deviceType}
        </Text>
        {tags}
      </div>
      <div className={styles.footer}>
        <a
          href={
            device.journey?.pid
              ? `/portal/journey/${device.journey._id}`
              : undefined
          }
          className={`${styles.footerLink} ${
            !device.journey?.pid ? styles.disabled : ''
          }`}
        >
          View Details
        </a>
      </div>
    </div>
  );

  return { header, body };
};

export default FleetViewShipperCard;
