import React, { ComponentType, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import NavBadge from './NavBadge';
import { IconProps } from '../../../aurora/icons/IconBase';
import styles from './NavItem.module.scss';
import { Role } from '../../../state/auth/types';

export interface NavItemProps {
  icon: ComponentType<IconProps>;
  name: string;
  roles?: Role[];
  onClick?: () => void;
  badgeValue?: string;
  toggleSlideout?: () => void;
  shouldRender?: boolean;
}

const NavItem = ({
  name,
  onClick,
  badgeValue,
  icon: Icon,
  toggleSlideout,
}: NavItemProps) => {
  const itemRef = useRef(null);

  const row = (isActive: boolean) => (
    <>
      <Icon
        width={22}
        height={22}
        color="currentColor"
        className={styles.navIcon}
      />
      <div>{name}</div>
      {badgeValue && <NavBadge text={badgeValue} isActive={isActive} />}
    </>
  );

  if (onClick) {
    return (
      <div
        ref={itemRef}
        onClick={() => {
          onClick();
          toggleSlideout && toggleSlideout();
        }}
        className={styles.navItem}
      >
        {row(false)}
      </div>
    );
  }

  const path = '/portal/' + name.replace(/ +/g, '-').toLowerCase();

  return (
    <NavLink
      ref={itemRef}
      to={path}
      className={({ isActive }) =>
        `${styles.navItem} ${isActive ? styles.activeItem : ''}`
      }
      onClick={toggleSlideout && toggleSlideout}
    >
      {({ isActive }) => row(isActive)}
    </NavLink>
  );
};

export default NavItem;
