import { Map, Popup } from 'mapbox-gl';
import { MapPoint } from '../../../../../components/map/Map';
import './mapPopup.scss';
import { clearMapPopups } from '../../../../../components/map/mapHandlers';
import SegmentMapPopup from './SegmentMapPopup';
import { SegmentMapPopupProps } from './types';
import { renderPopup } from '../../../../../components/map/renderPopup';
import { SegmentMapPointProperties } from './mapUtils';

// this function allows us to trigger a popup on the map whenever we want instead of just on hover
export const triggerMapPopup = (
  segmentId: string,
  secSinceStart: number,
  isArtycViewer: boolean,
  location: { lat: number; long: number },
  mapPoints: MapPoint[],
  map: Map
) => {
  // find the matching map point
  const matchingPoint = mapPoints.find((point) => {
    const secSinceStarts = point.properties?.secSinceStarts;
    return (
      secSinceStarts &&
      Array.isArray(secSinceStarts) &&
      secSinceStarts.includes(secSinceStart)
    );
  });

  // first remove existing popups
  clearMapPopups();

  // create the popup and show it
  if (matchingPoint != undefined && matchingPoint.properties !== null) {
    const scrollPos = window.scrollY;
    map.flyTo({
      center: [location.long, location.lat],
      zoom: Math.max(12, map.getZoom()),
    });

    const props: SegmentMapPopupProps = {
      ...(matchingPoint.properties as SegmentMapPointProperties),
      segmentId,
      isArtycViewer,
    };
    const popupNode = renderPopup(SegmentMapPopup, props);

    new Popup()
      .setLngLat([location.long, location.lat])
      .setDOMContent(popupNode)
      .addTo(map);
    window.scrollTo(0, scrollPos);
  }
};
