import React, { ReactNode } from 'react';
import DataModal from '../DataModal';
import { Text } from '../../aurora/typography/Text/Text';

interface Props {
  title: string;
  notice: string;
  show: boolean;
  onClose: () => void;
  buttons: ReactNode;
}
const NoticeModal = ({ title, notice, show, onClose, buttons }: Props) => {
  return (
    <DataModal title={title} show={show} onClose={onClose} width="424px">
      <div style={{ padding: '24px' }}>
        <Text size="sm" color="sub600">
          {notice}
        </Text>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
            marginTop: '32px',
          }}
        >
          {buttons}
        </div>
      </div>
    </DataModal>
  );
};

export default NoticeModal;
