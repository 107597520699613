import React from 'react';
import StatusBadge from '../../../aurora/components/StatusBadge/StatusBadge';
import { IconCheckCircleFill, IconWarningFill } from '../../../aurora/icons';

interface Props {
  totalEvents: number;
}
const EventStatusBadge = ({ totalEvents }: Props) => {
  if (totalEvents === 0) {
    return (
      <StatusBadge icon={IconCheckCircleFill} content="None" status="green" />
    );
  }

  return (
    <StatusBadge
      icon={IconWarningFill}
      content={`${totalEvents} Event${totalEvents === 1 ? '' : 's'}`}
      status="orange"
    />
  );
};

export default EventStatusBadge;
