import React from 'react';
import { SegmentSnapshotEntity } from '../../../../state/segmentSnapshots/types';
import { useSx } from 'dripsy';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { Text } from '../../../../aurora/typography/Text/Text';
import { getSnapshotInternalTemp } from '../../../../utils/segmentSnapshot/data';
import { SegmentEntity } from '../../../../state/segments/types';

interface Props {
  segment: SegmentEntity | undefined;
  snapshots: SegmentSnapshotEntity[];
  showGraph: () => void;
}

const DeviceDataSection = ({ segment, snapshots, showGraph }: Props) => {
  const sx = useSx();

  if (segment === undefined) {
    return null;
  }

  const data = snapshots.map((snapshot) =>
    getSnapshotInternalTemp(snapshot, segment.deviceType)
  );

  return (
    <div
      style={sx({
        cursor: 'pointer',
      })}
      onClick={showGraph}
    >
      <Text
        size="sm"
        sx={{ marginBottom: '16px', color: 'gray50v1', fontWeight: '500' }}
      >
        Sleeve Temperature
      </Text>
      <Sparklines data={data}>
        <SparklinesLine
          style={{ strokeWidth: 2, stroke: '#0a77ff', fill: 'none' }}
        />
      </Sparklines>
    </div>
  );
};

export default DeviceDataSection;
