import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OrdersApi from '../../../../../api/ordersApi';
import { Button } from '../../../../../aurora/components/Button/Button';
import { Label } from '../../../../../aurora/typography/Label/Label';
import { Text } from '../../../../../aurora/typography/Text/Text';
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { selectAuth } from '../../../../../state/auth';
import { JourneyEntity } from '../../../../../state/journeys/types';
import { OrderEntity } from '../../../../../state/orders/types';
import { formatDateWithTime } from '../../../../../utils/dateUtil';
import InfoCard from '../InfoCard';
import styles from '../JourneyPageContainer.module.scss';

interface Props {
  journey: JourneyEntity;
}
const OrderInfoCard = ({ journey }: Props) => {
  const auth = useSelector(selectAuth);
  const axios = useAxiosPrivate();

  const [order, setOrder] = useState<OrderEntity | null>(null);

  useEffect(() => {
    const fetchData = async (orderId: string) => {
      const orderResp = await OrdersApi.getOrder(axios, auth, orderId);
      setOrder(orderResp);
    };

    if (journey.order) {
      fetchData(journey.order);
    }
  }, [journey.order]);

  if (journey.order === undefined || journey.order === null || order === null) {
    return null;
  }

  return (
    <InfoCard
      title="Order Information"
      headerAction={
        <Button
          type={'neutral'}
          size={'xxs'}
          style={'stroke'}
          label="View Order"
          href={`/portal/orders?orderId=${journey.order}`}
        />
      }
    >
      <div className={styles.textSection}>
        {order.poNumber && (
          <div>
            <Label span size="sm" color="strong900">
              {'PO Number: '}
            </Label>
            <Text span size="sm" color="sub600">
              {order.poNumber}
            </Text>
          </div>
        )}
        {order.customId && (
          <div>
            <Label span size="sm" color="strong900">
              {'Custom ID: '}
            </Label>
            <Text span size="sm" color="sub600">
              {order.customId}
            </Text>
          </div>
        )}
        <div>
          <Label span size="sm" color="strong900">
            {'Submission Date: '}
          </Label>
          <Text span size="sm" color="sub600">
            {formatDateWithTime(new Date(order.createdAt))}
          </Text>
        </div>
        {order.fulfilledAt && (
          <div>
            <Label span size="sm" color="strong900">
              {'Fulfillment Date: '}
            </Label>
            <Text span size="sm" color="sub600">
              {formatDateWithTime(new Date(order.fulfilledAt))}
            </Text>
          </div>
        )}
      </div>
    </InfoCard>
  );
};

export default OrderInfoCard;
