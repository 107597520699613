import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconCloseCircleFill: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase viewBox="0 0 20 20" {...props}>
    <Path
      d="M10 17.5C5.85775 17.5 2.5 14.1423 2.5 10C2.5 5.85775 5.85775 2.5 10 2.5C14.1423 2.5 17.5 5.85775 17.5 10C17.5 14.1423 14.1423 17.5 10 17.5ZM10 8.9395L7.879 6.81775L6.81775 7.879L8.9395 10L6.81775 12.121L7.879 13.1823L10 11.0605L12.121 13.1823L13.1823 12.121L11.0605 10L13.1823 7.879L12.121 6.81775L10 8.9395Z"
      fill="currentColor"
    />
  </IconBase>
);
