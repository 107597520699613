import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../aurora/components/Button/legacy/Button';
import { IconCheckCircle, IconDownload } from '../../../aurora/icons';
import { downloadCsvAction, markReviewedAction } from '../../../state/events';
import { AppDispatch } from '../../../state/store';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { EventEntity, EventStatusesEnum } from '../../../state/events/types';
import { selectAuth } from '../../../state/auth';

interface Props {
  events: EventEntity[];
}
const EventsBulkAction = ({ events }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);
  const anyNew = events.reduce(
    (any, event) => event.status === EventStatusesEnum.NEW || any,
    false
  );

  const eventIds = events.map((event) => event._id);
  const markAsReviewed = () => {
    dispatch(
      markReviewedAction({
        axios: axiosPrivate,
        auth,
        eventIds,
      })
    );
  };

  const downloadSegmentCsv = () => {
    dispatch(downloadCsvAction({ axios: axiosPrivate, auth, eventIds }));
  };

  const markReviewedButton = anyNew ? (
    <Button
      variant="secondary"
      label="Mark As Reviewed"
      iconLeft={IconCheckCircle}
      onClick={markAsReviewed}
    />
  ) : null;

  return (
    <>
      {markReviewedButton}
      <Button
        variant="secondary"
        label="Download Shipment"
        iconLeft={IconDownload}
        sx={{ marginX: '12px' }}
        onClick={downloadSegmentCsv}
      />
    </>
  );
};

export default EventsBulkAction;
