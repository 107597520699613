import React, { useEffect, useState, useRef } from 'react';
import { ShipmentEntity } from '../../../../state/shipments/types';
import { SegmentEntity } from '../../../../state/segments/types';
import styles from './JourneyPageContainer.module.scss';
import CoolingDetailsCard from './leftColumn/CoolingDetailsCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSnapshotsBySegment,
  setSnapshots,
} from '../../../../state/segments';
import { ShippingProfileEntity } from '../../../../state/shippingProfile/types';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { selectAuth } from '../../../../state/auth';
import { EventEntity } from '../../../../state/events/types';
import SegmentsApi from '../../../../api/segmentsApi';
import ShippingProfilesApi from '../../../../api/shippingProfilesApi';
import EventsApi from '../../../../api/eventsApi';
import SegmentDataSection from './rightColumn/SegmentDataSection';
import { JourneyEntity } from '../../../../state/journeys/types';
import CarrierTrackingCard from './leftColumn/CarrierTrackingCard';
import ShipmentDetailsCard from './leftColumn/ShipmentDetailsCard';
import ShipperInfoCard from './leftColumn/ShipperInfoCard';
import EventInfoCard from './leftColumn/EventInfoCard';
import { Heading } from '../../../../aurora/typography/Heading/Heading';
import ExcursionBanner from './rightColumn/ExcursionBanner';
import { Text } from '../../../../aurora/typography/Text/Text';
import { RootState } from '../../../../state/store';
import OrderInfoCard from './leftColumn/OrderInfoCard';

interface Props {
  journey: JourneyEntity;
  shipment: ShipmentEntity;
  segment?: SegmentEntity;
  isArtycViewer: boolean;
}
const RegulatedShipmentContainer = ({
  journey,
  shipment,
  segment,
  isArtycViewer,
}: Props) => {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [shippingProfile, setShippingProfile] = useState<
    ShippingProfileEntity | undefined
  >(undefined);
  const [events, setEvents] = useState<EventEntity[]>([]);
  const [viewEvents, setViewEvents] = useState(false);

  const snapshotsBySegment = useSelector(selectSnapshotsBySegment);
  const snapshots = snapshotsBySegment[segment?._id || ''] || [];

  const eventsCardRef = useRef<HTMLDivElement>(null);
  const { artycSnapshotsMode } = useSelector(
    (state: RootState) => state.journeys
  );

  // TODO: condense into one request so that it's more performant?
  const fetchData = async () => {
    setShippingProfile(undefined);
    setEvents([]);

    setIsLoading(true);
    setIsError(false);

    try {
      const shippingProfileId =
        segment?.shippingProfile || journey.shippingProfile;
      const shippingProfileResp = shippingProfileId
        ? await ShippingProfilesApi.getShippingProfile(
            axios,
            auth,
            shippingProfileId
          )
        : undefined;
      setShippingProfile(shippingProfileResp);

      if (segment !== undefined) {
        const snapshots = await SegmentsApi.getSegmentSnapshots(
          axios,
          auth,
          isArtycViewer,
          segment._id,
          artycSnapshotsMode
        );
        dispatch(setSnapshots({ [segment._id]: snapshots }));

        const eventResp = await EventsApi.getEventsForSegment(
          axios,
          auth,
          segment._id
        );
        setEvents(eventResp);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [shipment, segment, isArtycViewer, artycSnapshotsMode]);

  // when click view events, scroll to events card
  useEffect(() => {
    if (viewEvents && eventsCardRef.current) {
      setTimeout(() => {
        const element = eventsCardRef.current;
        if (element) {
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - 100;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }, 100);
    }
  }, [viewEvents]);

  // TODO: do we want any loading states for sections of it?
  return (
    <div className={styles.container}>
      <div className={styles.leftColumn}>
        <div>
          <Heading size="h4" color="strong900">
            {shipment.pid}
          </Heading>
          {isArtycViewer ? (
            <Text size="xl" color="strong900">
              {journey.companyName}
            </Text>
          ) : null}
        </div>
        <CoolingDetailsCard
          segment={segment}
          snapshots={snapshots}
          shippingProfile={shippingProfile}
          isArtycViewer={isArtycViewer}
        />
        <ShipmentDetailsCard shipment={shipment} />
        <CarrierTrackingCard shipment={shipment} />
        <OrderInfoCard journey={journey} />
        <ShipperInfoCard journey={journey} showOpen={false} />
        <EventInfoCard
          events={events}
          eventsCardRef={eventsCardRef}
          viewEvents={viewEvents}
          setViewEvents={setViewEvents}
          snapshots={snapshots}
        />
      </div>
      <div className={styles.rightColumn}>
        <ExcursionBanner
          events={events}
          onViewEvents={() => setViewEvents(true)}
        />
        <SegmentDataSection
          shipment={shipment}
          segment={segment}
          isArtycViewer={isArtycViewer}
          isLoading={isLoading}
          isError={isError}
        />
      </div>
    </div>
  );
};

export default RegulatedShipmentContainer;
