import { GeoJsonProperties } from 'geojson';
import { MapPoint, MapPointGroup } from '../../../../components/map/Map';
import {
  FleetViewDevice,
  FleetViewStatus,
} from '../../../../state/fleetView/types';
import { CirclePaint, SymbolLayerSpecification } from 'mapbox-gl';
import mapboxgl from 'mapbox-gl';
import { adjustOverlappingPoints, createClusters } from './mapClustering';
import { DeviceType } from '../../../../state/devices/types';
import FleetViewMapPointPopup from './FleetViewMapPointPopup';
import FleetViewMapClusterPopup from './FleetViewMapClusterPopup';

const deployedPaint: CirclePaint = {
  'circle-radius': 8,
  'circle-color': '#2970FF',
  'circle-stroke-width': 2,
  'circle-stroke-color': '#FFFFFF',
};

const standbyPaint: CirclePaint = {
  'circle-radius': 8,
  'circle-color': '#99A0AE',
  'circle-stroke-width': 2,
  'circle-stroke-color': '#FFFFFF',
};

const deployedClusterPaint: CirclePaint = {
  'circle-color': '#2970FF',
  'circle-radius': 12,
  'circle-stroke-width': 4,
  'circle-stroke-color': '#FFFFFF',
};

const standbyClusterPaint: CirclePaint = {
  'circle-color': '#99A0AE',
  'circle-radius': 12,
  'circle-stroke-width': 4,
  'circle-stroke-color': '#FFFFFF',
};

const hoverPaint = (circlePaint: CirclePaint) => {
  return {
    ...circlePaint,
    'circle-stroke-color':
      circlePaint?.['circle-color'] === '#99A0AE' ? '#F2F5F8' : '#B2CCFF',
    'circle-radius':
      circlePaint?.['circle-radius'] !== undefined
        ? (circlePaint?.['circle-radius'] as number) + 2
        : undefined,
  };
};

const clusterLabelStyle: Omit<SymbolLayerSpecification, 'id' | 'source'> = {
  type: 'symbol' as const,
  layout: {
    'text-field': ['get', 'point_count_str'],
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
  },
  paint: {
    'text-color': '#FFFFFF',
  },
};

export interface FleetViewPointProperties {
  id: string;
  serialNumber: string;
  deploymentStatus: FleetViewStatus;
  deviceType: DeviceType;
  battery?: number;
  tempC?: number;
}

const mkPoint = (device: FleetViewDevice): MapPoint => {
  const properties: FleetViewPointProperties = {
    id: device.serialNumber,
    serialNumber: device.serialNumber,
    deploymentStatus: device.deviceDeploymentStatus,
    deviceType: device.deviceType,
    battery: device.lastKnownSensorData?.battery,
    tempC: device.lastKnownSensorData?.tempC,
  };

  return {
    latitude: device.lastKnownLocation!.lat,
    longitude: device.lastKnownLocation!.long,
    properties,
  };
};

const pointsToGroups = (
  deployedClusters: MapPoint[],
  standbyClusters: MapPoint[],
  deployedSingles: MapPoint[],
  standbySingles: MapPoint[],
  onClick: (properties: GeoJsonProperties) => void
): MapPointGroup[] => {
  return [
    // Clusters
    {
      groupId: `${FleetViewStatus.DEPLOYED}-clusters`,
      geometry: 'Point',
      points: deployedClusters,
      groupStyle: {
        id: `${FleetViewStatus.DEPLOYED}-clusters`,
        type: 'circle',
        source: `${FleetViewStatus.DEPLOYED}-clusters`,
        paint: deployedClusterPaint,
      },
      onHover: {
        popupComponent: {
          component: FleetViewMapClusterPopup,
          componentProps: (properties) => ({
            serialNumbers: JSON.parse(properties?.serialNumbers),
          }),
        },
      },
    },
    {
      groupId: `${FleetViewStatus.ON_STANDBY}-clusters`,
      geometry: 'Point',
      points: standbyClusters,
      groupStyle: {
        id: `${FleetViewStatus.ON_STANDBY}-clusters`,
        type: 'circle',
        source: `${FleetViewStatus.ON_STANDBY}-clusters`,
        paint: standbyClusterPaint,
      },
      onHover: {
        popupComponent: {
          component: FleetViewMapClusterPopup,
          componentProps: (properties) => ({
            serialNumbers: JSON.parse(properties?.serialNumbers),
          }),
        },
      },
    },
    // Cluster Labels
    {
      groupId: `${FleetViewStatus.DEPLOYED}-clusters-labels`,
      geometry: 'Point',
      points: deployedClusters,
      groupStyle: {
        ...clusterLabelStyle,
        id: `${FleetViewStatus.DEPLOYED}-clusters-labels`,
        source: `${FleetViewStatus.DEPLOYED}-clusters-labels`,
      },
    },
    {
      groupId: `${FleetViewStatus.ON_STANDBY}-clusters-labels`,
      geometry: 'Point',
      points: standbyClusters,
      groupStyle: {
        ...clusterLabelStyle,
        id: `${FleetViewStatus.ON_STANDBY}-clusters-labels`,
        source: `${FleetViewStatus.ON_STANDBY}-clusters-labels`,
      },
    },
    // Individual points
    {
      groupId: `${FleetViewStatus.DEPLOYED}-singles`,
      geometry: 'Point',
      points: deployedSingles,
      groupStyle: {
        id: `${FleetViewStatus.DEPLOYED}-singles`,
        type: 'circle',
        source: `${FleetViewStatus.DEPLOYED}-singles`,
        paint: deployedPaint,
      },
      onHover: {
        popupComponent: {
          component: FleetViewMapPointPopup,
          componentProps: (properties) =>
            properties as FleetViewPointProperties,
        },
        paint: {
          hoverPaint: hoverPaint(deployedPaint),
          defaultPaint: deployedPaint,
          conditionalPaintCase: (properties) => [
            '==',
            ['get', 'serialNumber'],
            properties?.serialNumber,
          ],
        },
      },
      onClick: (properties: GeoJsonProperties) => {
        onClick(properties);
      },
    },
    {
      groupId: `${FleetViewStatus.ON_STANDBY}-singles`,
      geometry: 'Point',
      points: standbySingles,
      groupStyle: {
        id: `${FleetViewStatus.ON_STANDBY}-singles`,
        type: 'circle',
        source: `${FleetViewStatus.ON_STANDBY}-singles`,
        paint: standbyPaint,
      },
      onHover: {
        popupComponent: {
          component: FleetViewMapPointPopup,
          componentProps: (properties) =>
            properties as FleetViewPointProperties,
        },
        paint: {
          hoverPaint: hoverPaint(standbyPaint),
          defaultPaint: standbyPaint,
          conditionalPaintCase: (properties) => [
            '==',
            ['get', 'serialNumber'],
            properties?.serialNumber,
          ],
        },
      },
      onClick: (properties: GeoJsonProperties) => {
        onClick(properties);
      },
    },
  ];
};

export const mkPointGroups = (
  devices: FleetViewDevice[],
  map: mapboxgl.Map | null,
  onClick: (properties: FleetViewPointProperties) => void
): MapPointGroup[] => {
  const { deployedPoints, standbyPoints } = devices.reduce(
    (acc, device) => {
      const point = mkPoint(device);
      if (device.deviceDeploymentStatus === FleetViewStatus.DEPLOYED) {
        acc.deployedPoints.push(point);
      } else if (device.deviceDeploymentStatus === FleetViewStatus.ON_STANDBY) {
        acc.standbyPoints.push(point);
      }
      return acc;
    },
    { deployedPoints: [], standbyPoints: [] } as {
      deployedPoints: MapPoint[];
      standbyPoints: MapPoint[];
    }
  );

  // need the map to create clusters based on zoom level of the map
  if (map !== null) {
    const { clusters: deployedClusters, singles: deployedSingles } =
      createClusters(deployedPoints, map);
    const { clusters: standbyClusters, singles: standbySingles } =
      createClusters(standbyPoints, map);

    adjustOverlappingPoints(
      [...deployedClusters, ...deployedSingles],
      [...standbyClusters, ...standbySingles],
      map
    );

    return pointsToGroups(
      deployedClusters,
      standbyClusters,
      deployedSingles,
      standbySingles,
      onClick as (properties: GeoJsonProperties) => void
    );
  }

  // map hasn't loaded yet, just show all the points so we can at least bound the map
  return pointsToGroups(
    [],
    [],
    deployedPoints,
    standbyPoints,
    onClick as (properties: GeoJsonProperties) => void
  );
};
