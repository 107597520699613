import { DeviceType } from '../devices/types';

export enum FleetViewStatus {
  DEPLOYED = 'Deployed',
  ON_STANDBY = 'On Standby',
}

export const FleetViewFilter = {
  ...FleetViewStatus,
  ...DeviceType,
} as const;

export enum FleetViewSort {
  UPDATED_DESC = 'Most Recently Updated',
  DEPLOYED_ASC = 'Deployed Date (Old to New)',
  DEPLOYED_DESC = 'Deployed Date (New to Old)',
}

export interface FleetViewDevice {
  serialNumber: string;
  deviceDeploymentStatus: FleetViewStatus;
  deviceType: DeviceType;
  journey?: {
    _id: string;
    pid: string;
    startTime: string;
  };
  lastKnownSensorData?: {
    battery: number;
    tempC: number;
    timestamp: string;
  };
  lastKnownLocation?: {
    lat: number;
    long: number;
    timestamp: string;
  };
  temperatureExcursions: {
    eventStart: string;
    eventEnd?: string;
    eventDetails?: object;
  }[];
}
