import {
  CreateOrderRequestBody,
  UpdateOrderRequestBody,
  UpdateOrderShipmentBody,
} from '../../../../api/ordersApi';
import {
  AddressTypeEnum,
  NewAddressEntity,
  NewOrExistingAddressEntity,
} from '../../../../state/addresses/types';
import { JourneyEntity } from '../../../../state/journeys/types';
import { OrderEntity } from '../../../../state/orders/types';
import {
  ShipmentEntity,
  ShipmentTypeEnum,
  ShippoCarrierEnum,
} from '../../../../state/shipments/types';

export interface OrderFormData {
  company: string;
  shippingProfile: string;
  device?: string;
  expectedDeliveryDate?: string;
  poNumber?: string;
  customId?: string;
  user: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    postalCode: string;
    trackingNumber?: string;
    carrier?: ShippoCarrierEnum;
    phoneNumber?: string;
  };
  lab: (
    | Omit<NewAddressEntity, 'country' | 'addressType'>
    | { addressId: string }
  ) & {
    trackingNumber?: string;
    carrier?: ShippoCarrierEnum;
  };
}

export const createFormToBody = (
  formData: OrderFormData
): CreateOrderRequestBody => {
  const userAddress: NewAddressEntity = {
    addressName: undefined,
    attn: undefined,
    addressLine1: formData.user.addressLine1,
    addressLine2: formData.user.addressLine2,
    city: formData.user.city,
    state: formData.user.state,
    postalCode: formData.user.postalCode,
    country: 'US',
    addressType: AddressTypeEnum.USER,
    phoneNumber: formData.user.phoneNumber,
  };

  const labAddress: NewOrExistingAddressEntity =
    'addressId' in formData.lab
      ? {
          _id: formData.lab.addressId,
        }
      : {
          addressName: formData.lab.addressName,
          attn: formData.lab.attn,
          addressLine1: formData.lab.addressLine1,
          addressLine2: formData.lab.addressLine2,
          city: formData.lab.city,
          state: formData.lab.state,
          postalCode: formData.lab.postalCode,
          country: 'US',
          addressType: AddressTypeEnum.LAB,
          phoneNumber: formData.lab.phoneNumber,
        };

  return {
    ...formData,
    // for now we assume the shipments have the same structure
    // first leg is to user + unregulated, second is to lab + regulated
    shipments: [
      {
        leg: 1,
        shipmentType: ShipmentTypeEnum.UNREGULATED,
        carrier: formData.user.carrier,
        trackingNumber: formData.user.trackingNumber,
        // TODO: should we hard code the warehouse as the origin?
        destination: userAddress,
      },
      {
        leg: 2,
        shipmentType: ShipmentTypeEnum.REGULATED,
        carrier: formData.lab.carrier,
        trackingNumber: formData.lab.trackingNumber,
        origin: userAddress,
        destination: labAddress,
      },
    ],
  };
};

export interface FulfillOrderFormData {
  company: string;
  device?: string;
  expectedDeliveryDate?: string;
  shipments: {
    shipmentId: string;
    trackingNumber?: string;
    carrier?: ShippoCarrierEnum;
    addressId: string;
    addressName?: string;
    attn?: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    postalCode: string;
    phoneNumber?: string;
  }[];
}

export const fulfillFormToBody = (
  formData: FulfillOrderFormData
): UpdateOrderRequestBody => {
  const shipments = formData.shipments.reduce((acc, shipment) => {
    const shipmentBody: UpdateOrderShipmentBody = {
      trackingNumber: shipment.trackingNumber,
      carrier: shipment.carrier,
      destination: {
        _id: shipment.addressId,
        addressName: shipment.addressName,
        attn: shipment.attn,
        addressLine1: shipment.addressLine1,
        addressLine2: shipment.addressLine2,
        city: shipment.city,
        state: shipment.state,
        postalCode: shipment.postalCode,
        phoneNumber: shipment.phoneNumber,
      },
    };

    const newAcc = {
      ...acc,
      [shipment.shipmentId]: shipmentBody,
    };

    return newAcc;
  }, {});

  return {
    ...formData,
    shipments,
  };
};

export const orderToFulfillFormData = (
  order: OrderEntity,
  journey: JourneyEntity,
  shipments: ShipmentEntity[]
): FulfillOrderFormData => {
  return {
    company: order.company,
    device: journey.device || undefined,
    expectedDeliveryDate: order.expectedDeliveryDate || undefined,
    shipments: shipments.map((shipment) => {
      const destination = shipment.destination;

      return {
        shipmentId: shipment._id,
        trackingNumber: shipment.trackingNumber ?? undefined,
        carrier: shipment.carrier ?? undefined,
        addressName: destination?.addressName ?? undefined,
        attn: destination?.attn ?? undefined,
        addressId: destination?._id || '',
        addressLine1: destination?.addressLine1 ?? '',
        addressLine2: destination?.addressLine2 ?? undefined,
        city: destination?.city ?? '',
        state: destination?.state ?? '',
        postalCode: destination?.postalCode ?? '',
        phoneNumber: destination?.phoneNumber ?? '',
      };
    }),
  };
};
