import React, { useCallback, useEffect, useState } from 'react';
import { SegmentEntity } from '../../../../../state/segments/types';
import { useDispatch, useSelector } from 'react-redux';
import SnapshotsGraph, { ExternalTempData } from './SnapshotsGraph';
import {
  ButtonGroup,
  ButtonProps,
} from '../../../../../aurora/components/ButtonGroup/ButtonGroup';
import ArtycPanel from './ArtycPanel';
import { selectSnapshotsBySegment } from '../../../../../state/segments';
import styles from './SnapshotsGraphContainer.module.scss';
import GraphDropdown from './GraphDropdown';
import InfoCard from '../InfoCard';
import XAxisRangeSelector from './XAxisRangeSelector';
import { convertTemp } from './chartOptions';
import { Chart as ChartJS } from 'chart.js';
import { RootState } from '../../../../../state/store';
import { setHoveredSnapshot } from '../../../../../state/journeys';
import { triggerTooltip } from './Tooltip';
import { SegmentSnapshotEntity } from '../../../../../state/segmentSnapshots/types';
import { getSnapshotInternalTemp } from '../../../../../utils/segmentSnapshot/data';

interface Props {
  segment: SegmentEntity;
  isArtycViewer: boolean;
}

export interface TimeRange {
  startMs: number;
  endMs: number;
}

const SnapshotsGraphContainer = ({ segment, isArtycViewer }: Props) => {
  const snapshotsBySegment = useSelector(selectSnapshotsBySegment);
  const snapshots = snapshotsBySegment[segment._id];

  const [tempUnit, setTempUnit] = useState<'C' | 'F'>('C');

  const [tempThresholdsC, setTempThresholdsC] = useState<number[]>([]);
  const [minTempAxisC, setMinTempAxisC] = useState<number | undefined>(
    undefined
  );
  const [maxTempAxisC, setMaxTempAxisC] = useState<number | undefined>(
    undefined
  );
  const [showDateAsDuration, setShowDateAsDuration] = useState<boolean>(true);
  const [showLidOpen, setShowLidOpen] = useState<boolean>(false);
  const [externalTempData, setExternalTempData] = useState<ExternalTempData[]>(
    []
  );
  const [showXAxisRangeSelector, setShowXAxisRangeSelector] =
    useState<boolean>(false);
  const chartRef =
    React.useRef<
      ChartJS<'line', (number | { x: number; y: number })[], unknown>
    >(null);

  const thresholdCenter = segment.setTemperature;
  // TODO: once we have shipping profiles, change these to match the min/max temp from there
  useEffect(() => {
    setTempThresholdsC([thresholdCenter - 3, thresholdCenter + 3]);
  }, [segment]);

  const temps: ButtonProps[] = [
    {
      label: '°C',
      onClick: () => setTempUnit('C'),
    },
    {
      label: '°F',
      onClick: () => setTempUnit('F'),
    },
  ];

  const [selectedTimeRange, setSelectedTimeRange] = useState<
    TimeRange | undefined
  >(undefined);

  const { hoveredSnapshot } = useSelector((state: RootState) => state.journeys);

  const dispatch = useDispatch();
  const onHover = useCallback(
    (snapshot: SegmentSnapshotEntity, index: number) => {
      if (
        hoveredSnapshot === null ||
        hoveredSnapshot.snapshot.secSinceStart !== snapshot.secSinceStart
      ) {
        dispatch(
          setHoveredSnapshot({
            hovered: { snapshot, trigger: 'graph', index },
          })
        );
      }
    },
    [dispatch, hoveredSnapshot]
  );

  useEffect(() => {
    if (hoveredSnapshot !== null && hoveredSnapshot.trigger !== 'graph') {
      // if we're hovering from somewhere else,
      // clear the selected time range or else can end in weird edge cases
      setSelectedTimeRange(undefined);

      triggerTooltip(
        chartRef.current,
        hoveredSnapshot.snapshot,
        hoveredSnapshot.index,
        segment.deviceType
      );
    }
  }, [hoveredSnapshot]);

  return (
    <InfoCard
      title="Temperature Data"
      headerAction={<ButtonGroup buttonProps={temps} initialActiveIdx={0} />}
    >
      <div className={styles.graphDropdown}>
        <GraphDropdown>
          <ArtycPanel
            isArtycViewer={isArtycViewer}
            segments={[segment]}
            tempThresholdsC={tempThresholdsC}
            setTempThresholdsC={setTempThresholdsC}
            setMinTempAxisC={setMinTempAxisC}
            setMaxTempAxisC={setMaxTempAxisC}
            showDateAsDuration={showDateAsDuration}
            setShowDateAsDuration={setShowDateAsDuration}
            showLidOpen={showLidOpen}
            setShowLidOpen={setShowLidOpen}
            tempUnit={tempUnit}
            setExternalTempData={setExternalTempData}
            showXAxisRangeSelector={showXAxisRangeSelector}
            setShowXAxisRangeSelector={(show) => {
              setShowXAxisRangeSelector(show);
              if (!show) {
                setSelectedTimeRange(undefined);
              }
            }}
          />
        </GraphDropdown>
      </div>
      <div className={styles.graphPanel}>
        <SnapshotsGraph
          ref={chartRef}
          segment={segment}
          snapshots={snapshots}
          externalTempData={externalTempData}
          tempUnit={tempUnit}
          isArtycViewer={isArtycViewer}
          tempThresholdsC={tempThresholdsC}
          minTempAxisC={minTempAxisC}
          maxTempAxisC={maxTempAxisC}
          showLidOpen={showLidOpen}
          showDateAsDuration={showDateAsDuration}
          selectedTimeRange={selectedTimeRange}
          onHover={(index) => onHover(snapshots[index], index)}
        />
        {showXAxisRangeSelector && (
          <XAxisRangeSelector
            data={snapshots.map((snapshot) => ({
              timestampMs: new Date(snapshot.timestamp).valueOf(),
              internalTemp: convertTemp(tempUnit)(
                getSnapshotInternalTemp(snapshot, segment.deviceType)
              ),
            }))}
            chartRef={chartRef}
            showDateAsDuration={showDateAsDuration}
            onRangeSelect={setSelectedTimeRange}
          />
        )}
      </div>
    </InfoCard>
  );
};

export default SnapshotsGraphContainer;
