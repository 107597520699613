import React from 'react';
import { useDispatch } from 'react-redux';
import PageDateFilter from '../../../../components/filters/PageDateFilter/PageDateFilter';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

interface OrderDateFilterProps {
  dateRange?: {
    startDate?: string;
    endDate?: string;
  };
  action: ActionCreatorWithPayload<[string | undefined, string | undefined]>;
  placeholder: string;
  maxDate?: Date | null;
}

const OrderDateFilter = ({
  dateRange,
  action,
  placeholder,
  maxDate,
}: OrderDateFilterProps) => {
  const dispatch = useDispatch();

  const setDateFilters = (
    startDate: Date | undefined,
    endDate: Date | undefined
  ) => dispatch(action([startDate?.toISOString(), endDate?.toISOString()]));

  const clearDateFilters = () => dispatch(action([undefined, undefined]));

  return (
    <PageDateFilter
      setDateFilters={setDateFilters}
      clearDateFilters={clearDateFilters}
      filters={[
        dateRange?.startDate ? new Date(dateRange.startDate) : undefined,
        dateRange?.endDate ? new Date(dateRange.endDate) : undefined,
      ]}
      placeholder={placeholder}
      maxDate={maxDate}
    />
  );
};

export default OrderDateFilter;
