import {
  convertTemp,
  mkDatapoint,
} from '../../../pages/journeys/components/journeyPage/graph/chartOptions';
import { DeviceType } from '../../../state/devices/types';
import { MedstowMicroSnapshotEntity } from '../../../state/segmentSnapshots/medstowMicro';
import { SegmentSnapshotEntity } from '../../../state/segmentSnapshots/types';
import { SnapshotDataFetch } from './types';

export const isMedstowMicroSnapshot = (
  snapshot: SegmentSnapshotEntity,
  deviceType: DeviceType
): snapshot is MedstowMicroSnapshotEntity => {
  return deviceType === DeviceType.MedstowMicro;
};

export const medstowMicroFetch: SnapshotDataFetch<MedstowMicroSnapshotEntity> =
  {
    getInternalTemp: (snapshot) => snapshot.temps.sleeve,
    getExternalTemp: (snapshot) => snapshot.temps.hs,
    getSoc: (snapshot) => snapshot.soc,
    getChartedData: (snapshots, isArtycViewer, tempUnit) => {
      return [
        {
          label: `${isArtycViewer ? 'Heat Sink' : 'External'} (°${tempUnit})`,
          data: snapshots.map((snapshot) =>
            mkDatapoint(snapshot, convertTemp(tempUnit)(snapshot.temps.hs))
          ),
          borderColor: '#eea23e',
          yAxisID: 'temperature',
          pointRadius: 0,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: '#eea23e',
        },
        {
          label: `${isArtycViewer ? 'Sleeve' : 'Internal'} (°${tempUnit})`,
          data: snapshots.map((snapshot) =>
            mkDatapoint(snapshot, convertTemp(tempUnit)(snapshot.temps.sleeve))
          ),
          borderColor: '#0c77ff',
          yAxisID: 'temperature',
          pointRadius: 0,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: '#0c77ff',
        },
        {
          label: 'Battery (%)',
          data: snapshots.map((snapshot) =>
            mkDatapoint(snapshot, snapshot.soc)
          ),
          borderColor: '#5a6473',
          yAxisID: 'temperature',
          pointRadius: 0,
          pointHoverRadius: 4,
          pointHoverBackgroundColor: '#5a6473',
        },
        ...(isArtycViewer
          ? [
              {
                label: `Evap (°${tempUnit})`,
                data: snapshots.map((snapshot) =>
                  mkDatapoint(
                    snapshot,
                    convertTemp(tempUnit)(snapshot.temps.evap)
                  )
                ),
                borderColor: '#7c31c6',
                yAxisID: 'temperature',
                pointRadius: 0,
                pointHoverRadius: 4,
                pointHoverBackgroundColor: '#7c31c6',
              },
              {
                label: 'Output (V)',
                data: snapshots.map((snapshot) =>
                  mkDatapoint(snapshot, parseFloat(snapshot.outputV))
                ),
                borderColor: '#29a63b',
                yAxisID: 'voltage',
                pointRadius: 0,
                pointHoverRadius: 4,
                pointHoverBackgroundColor: '#29a63b',
              },
            ]
          : []),
      ];
    },
  };
