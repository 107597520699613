import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconArrowRight, IconDownload } from '../../../../aurora/icons';
import ActionButton from '../../../../components/table/ActionButton';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { selectAuth } from '../../../../state/auth';
import { JourneyEntity } from '../../../../state/journeys/types';
import { downloadCsvAction } from '../../../../state/segments';
import { AppDispatch } from '../../../../state/store';
import styles from './JourneyTableAction.module.scss';

interface Props {
  journey: JourneyEntity;
  isArtycViewer: boolean;
}
const JourneyTableAction = ({ journey, isArtycViewer }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  return (
    <div className={styles.row}>
      <ActionButton
        icon={IconArrowRight}
        href={`/portal/journey/${journey._id}`}
        label="View Shipment"
      />
      {journey.segmentId !== undefined ? (
        <ActionButton
          label="Download CSV"
          icon={IconDownload}
          onClick={() =>
            dispatch(
              downloadCsvAction({
                axios: axiosPrivate,
                auth,
                journeys: [journey],
                fullLog: false,
                isArtycViewer,
              })
            )
          }
        />
      ) : null}
    </div>
  );
};

export default JourneyTableAction;
