import React from 'react';
import DeploymentStatusBadge from '../DeploymentStatusBadge';
import styles from './FleetViewMapPointPopup.module.scss';
import { FleetViewPointProperties } from './mkPointGroups';
import { IconTemperature, IconBattery } from '../../../../aurora/icons';

const FleetViewMapPointPopup = ({
  properties,
}: {
  properties: FleetViewPointProperties;
}) => {
  return (
    <div className={styles.popup}>
      <DeploymentStatusBadge deploymentStatus={properties?.deploymentStatus} />
      <div className={styles.deviceInfo}>
        <div className={styles.serialNumber}>
          {`#${properties?.serialNumber}`}
        </div>
        <div>{properties?.deviceType}</div>
      </div>

      {properties.battery || properties.tempC ? (
        <div className={styles.tagRow}>
          {properties.battery ? (
            <div className={styles.tag}>
              <IconBattery width={16} height={16} />
              <div className={styles.tagText}>{`${Math.round(
                properties.battery
              )}%`}</div>
            </div>
          ) : null}
          {properties.tempC ? (
            <div className={styles.tag}>
              <IconTemperature width={16} height={16} />
              <div
                className={styles.tagText}
              >{`${properties.tempC.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              })}°C`}</div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default FleetViewMapPointPopup;
