import { ChartDataset } from 'chart.js';
import { DeviceType } from '../../../state/devices/types';
import { BaseSegmentSnapshotEntity } from '../../../state/segmentSnapshots/base';
import { SegmentSnapshotEntity } from '../../../state/segmentSnapshots/types';
import { medstow5LIFetch } from './medstow5LI';
import { medstowMicroFetch } from './medstowMicro';
import { SnapshotDataFetch } from './types';

const getDataFetch = <T extends BaseSegmentSnapshotEntity>(
  shipperType: DeviceType
): SnapshotDataFetch<T> => {
  switch (shipperType) {
    case DeviceType.MedstowMicro:
      return medstowMicroFetch as unknown as SnapshotDataFetch<T>;
    case DeviceType.Medstow5LI:
      return medstow5LIFetch as unknown as SnapshotDataFetch<T>;
    default:
      const exhaustiveCheck: never = shipperType;
      throw new Error(`Unhandled device type: ${exhaustiveCheck}`);
  }
};

export const getSnapshotInternalTemp = (
  snapshot: SegmentSnapshotEntity,
  shipperType: DeviceType
): number => {
  const fetch = getDataFetch(shipperType);
  return fetch.getInternalTemp(snapshot);
};

export const getSnapshotExternalTemp = (
  snapshot: SegmentSnapshotEntity,
  shipperType: DeviceType
): number => {
  const fetch = getDataFetch(shipperType);
  return fetch.getExternalTemp(snapshot);
};

export const getSnapshotSoc = (
  snapshot: SegmentSnapshotEntity,
  shipperType: DeviceType
): number => {
  const fetch = getDataFetch(shipperType);
  return fetch.getSoc(snapshot);
};

export const getSnapshotChartedData = (
  shipperType: DeviceType,
  snapshots: SegmentSnapshotEntity[],
  isArtycViewer: boolean,
  tempUnit: 'C' | 'F'
): ChartDataset<'line'>[] => {
  const fetch = getDataFetch(shipperType);
  return fetch.getChartedData(snapshots, isArtycViewer, tempUnit);
};
