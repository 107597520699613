import React from 'react';
import ModalAction from '../../../../components/table/ModalAction';
import { IconEdit } from '../../../../aurora/icons';
import { loadOrders } from '../../../../state/orders';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import {
  fulfillFormToBody,
  FulfillOrderFormData,
} from '../orderForm/mapOrderData';
import { useSelector } from 'react-redux';
import { isArtyc, isCompanyAdmin, selectAuth } from '../../../../state/auth';
import { differenceInDays } from 'date-fns';
import { OrderEntity, OrderStatus } from '../../../../state/orders/types';
import OrdersApi from '../../../../api/ordersApi';
import FulfillOrderFormModal from '../orderForm/FulfillOrderFormModal';

interface Props {
  order: OrderEntity;
  isArtycViewer: boolean;
}
const EditOrderButton = ({ order, isArtycViewer }: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  const canEdit = isArtyc(auth) || isCompanyAdmin(auth);

  // customer can't edit after 7 days before expectedDeliveryDate
  const withinLeadTime = order.expectedDeliveryDate
    ? differenceInDays(new Date(order.expectedDeliveryDate), new Date()) >= 6
    : true;
  const unfulfilled = order.status === OrderStatus.PENDING;

  const editable = isArtyc(auth) || (withinLeadTime && unfulfilled);

  if (!canEdit || !editable) {
    return null;
  }

  // TODO(order): swap out FulfillOrderForm for CaasOrderForm with values filled in
  return (
    <ModalAction
      label="Edit Order"
      modalTitle="Edit Order"
      icon={IconEdit}
      stateName="orders"
      modalContent={(onDataChange, onClose) => (
        <FulfillOrderFormModal
          order={order}
          isArtycViewer={isArtycViewer}
          onSubmit={async (data: FulfillOrderFormData) => {
            const body = fulfillFormToBody(data);
            await OrdersApi.updateOrder(axiosPrivate, auth, order._id, body);
            await onDataChange();
          }}
          onClose={onClose}
          fulfilling={false}
        />
      )}
      loadDataAction={loadOrders}
      modalWidth="446px"
    />
  );
};

export default EditOrderButton;
