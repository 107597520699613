import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconFormLine: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props}>
    <Path
      d="M19.6999 21H5.2999C5.06121 21 4.83229 20.9052 4.66351 20.7364C4.49472 20.5676 4.3999 20.3387 4.3999 20.1V3.9C4.3999 3.66131 4.49472 3.43239 4.66351 3.2636C4.83229 3.09482 5.06121 3 5.2999 3H19.6999C19.9386 3 20.1675 3.09482 20.3363 3.2636C20.5051 3.43239 20.5999 3.66131 20.5999 3.9V20.1C20.5999 20.3387 20.5051 20.5676 20.3363 20.7364C20.1675 20.9052 19.9386 21 19.6999 21ZM18.7999 19.2V4.8H6.1999V19.2H18.7999ZM8.8999 7.5H16.0999V9.3H8.8999V7.5ZM8.8999 11.1H16.0999V12.9H8.8999V11.1ZM8.8999 14.7H16.0999V16.5H8.8999V14.7Z"
      fill="currentColor"
    />
  </IconBase>
);
