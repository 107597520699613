import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { SegmentEntity } from './types';
import { Auth } from '../auth/types';
import SegmentsApi from '../../api/segmentsApi';
import { showToast } from '../../aurora/components/Toast/Toast';
import { RootState } from '../store';
import { JourneyEntity } from '../journeys/types';
import { SegmentSnapshotEntity } from '../segmentSnapshots/types';

export type SegmentToSnapshotsMap = Record<
  SegmentEntity['_id'],
  SegmentSnapshotEntity[]
>;

export interface SegmentState {
  snapshotsBySegment: SegmentToSnapshotsMap;
  segmentById: Record<SegmentEntity['_id'], SegmentEntity | undefined>;
}

const initialState: SegmentState = {
  snapshotsBySegment: {},
  segmentById: {},
};

interface BulkCsvPayload {
  axios: AxiosInstance;
  auth: Auth | null;
  journeys: JourneyEntity[];
}
export const downloadCsvAction = createAsyncThunk(
  'segments/downloadCsv',
  ({
    axios,
    auth,
    journeys,
    fullLog,
    isArtycViewer,
  }: BulkCsvPayload & { fullLog: boolean; isArtycViewer: boolean }) => {
    const segmentIds = journeys
      .filter((journey) => journey.segmentId !== undefined)
      .map((journey) => journey.segmentId!);

    if (fullLog) {
      return SegmentsApi.downloadLogCsv(axios, isArtycViewer, segmentIds);
    }
    return SegmentsApi.downloadSegmentCsv(
      axios,
      auth,
      isArtycViewer,
      segmentIds
    );
  }
);

const segmentSlice = createSlice({
  name: 'segments',
  initialState,
  reducers: {
    setSnapshots: (state, action: PayloadAction<SegmentToSnapshotsMap>) => {
      state.snapshotsBySegment = {
        ...state.snapshotsBySegment,
        ...action.payload,
      };
    },
    setSegment: (state, action: PayloadAction<SegmentEntity>) => {
      state.segmentById = {
        ...state.segmentById,
        ...{ [action.payload._id]: action.payload },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadCsvAction.pending, () => {
      showToast({
        type: 'loading',
        title: 'Downloading',
        text: 'Preparing your files for download. Please wait...',
      });
    });

    builder.addCase(downloadCsvAction.fulfilled, (state, action) => {
      const url = window.URL.createObjectURL(new Blob([action.payload]));

      const link = document.createElement('a');
      link.href = url;
      if (action.payload.type === 'text/csv') {
        const journey = action.meta.arg.journeys[0];
        const { serialNumber, pid } = journey;
        const logType = action.meta.arg.fullLog ? 'full_log' : 'shipment';

        link.setAttribute('download', `${serialNumber}-${logType}-${pid}.csv`);
      } else {
        // customer facing, still best to name it shipments
        link.setAttribute('download', 'shipments.zip');
      }

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      showToast({
        type: 'success',
        title: 'Success!',
        text: 'Your files have successfully downloaded.\nPlease check your downloads folder.',
      });
    });

    builder.addCase(downloadCsvAction.rejected, () => {
      showToast({
        type: 'error',
        title: 'Uh oh...',
        text: 'There was an error preparing your files. \nPlease try again or contact support if it continues.',
      });
    });
  },
});

export const selectSnapshotsBySegment = (state: RootState) =>
  state.segments.snapshotsBySegment;

export const { setSnapshots, setSegment } = segmentSlice.actions;

export default segmentSlice.reducer;
