import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from './auth';
import EventReducer from './events';
import CompanyReducer from './companies';
import UserReducer from './users';
import JourneyReducer from './journeys';
import SegmentReducer from './segments';
import DeviceReducer from './devices';
import ShippingProfileReducer from './shippingProfile';
import CustomerCostReportsReducer from './customerCostReports';
import PcbTestsReducer from './pcbTests';
import addressesReducer from './addresses';
import { useSelector } from 'react-redux';
import { EntityState, EntityStateExtension } from './types';
import FleetViewReducer from './fleetView';
import OrderReducer from './orders';

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    events: EventReducer,
    companies: CompanyReducer,
    users: UserReducer,
    journeys: JourneyReducer,
    segments: SegmentReducer,
    devices: DeviceReducer,
    shippingProfiles: ShippingProfileReducer,
    customerCostReports: CustomerCostReportsReducer,
    pcbTests: PcbTestsReducer,
    addresses: addressesReducer,
    fleetView: FleetViewReducer,
    orders: OrderReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// used to generically fetch one of the states that extend EntityState
// have to cast as unknown first because typescript has a hard time deciphering the type
export const useEntityState = <T>(stateName: EntityStateExtension<T>) => {
  return useSelector(
    (state: RootState) => state[stateName] as unknown as EntityState<T>
  );
};
