import React, { useState } from 'react';
import {
  SegmentEntity,
  SegmentStatus,
} from '../../../../../state/segments/types';
import { Text } from '../../../../../aurora/typography/Text/Text';
import {
  formatDateTime,
  secondsToDuration,
} from '../../../../../utils/dateUtil';
import { Label } from '../../../../../aurora/typography/Label/Label';
import InfoCard from '../InfoCard';
import DataModal from '../../../../../components/DataModal';
import TempManagementSection from './TempManagementSection';
import { Button } from '../../../../../aurora/components/Button/Button';
import { ShippingProfileEntity } from '../../../../../state/shippingProfile/types';
import {
  IconCheckCircleFill,
  IconTemperature,
  IconSun,
  IconLightning,
} from '../../../../../aurora/icons';
import styles from './CoolingDetailsCard.module.scss';
import { SegmentSnapshotEntity } from '../../../../../state/segmentSnapshots/types';
import {
  getSnapshotExternalTemp,
  getSnapshotInternalTemp,
  getSnapshotSoc,
} from '../../../../../utils/segmentSnapshot/data';

interface Tag {
  label: string;
  value: string | number | undefined;
  artycOnly?: boolean;
}

interface Props {
  segment: SegmentEntity | undefined;
  snapshots: SegmentSnapshotEntity[];
  shippingProfile: ShippingProfileEntity | undefined;
  isArtycViewer: boolean;
}
const CoolingDetailsCard = ({
  segment,
  snapshots,
  shippingProfile,
  isArtycViewer,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const isComplete = segment?.status === SegmentStatus.COMPLETE;

  // sometimes first value is 0
  const startingBattery =
    segment && snapshots.length > 0
      ? snapshots.length > 1
        ? Math.max(
            getSnapshotSoc(snapshots[0], segment.deviceType),
            getSnapshotSoc(snapshots[1], segment.deviceType)
          )
        : 0
      : 0;
  const currentSnapshot =
    snapshots.length > 0 ? snapshots[snapshots.length - 1] : undefined;
  const currentBattery =
    segment &&
    currentSnapshot &&
    getSnapshotSoc(currentSnapshot, segment.deviceType);

  const tags: Tag[] = [
    { label: 'Cooling Start', value: formatDateTime(segment?.startTime) },
    { label: 'Cooling End', value: formatDateTime(segment?.endTime) },
    {
      label: 'Cooling Duration',
      value: segment && secondsToDuration(segment.lengthSec),
    },
    {
      label: 'Snapshots',
      value: snapshots.length > 0 ? snapshots.length : undefined,
      artycOnly: true,
    },
    {
      label: 'Battery Used',
      value:
        currentBattery && isComplete
          ? `${startingBattery - currentBattery}%`
          : undefined,
    },
    { label: 'Segment ID', value: segment && segment._id, artycOnly: true },
    {
      label: 'Uploaded At',
      value: segment && formatDateTime(segment.createdAt),
      artycOnly: true,
    },
    {
      label: 'Last Updated',
      value:
        snapshots.length > 0
          ? formatDateTime(currentSnapshot!.createdAt)
          : undefined,
    },
  ];

  const banner = isComplete ? (
    <div className={styles.banner}>
      <div className={styles.bannerIcon}>
        <IconCheckCircleFill width={20} height={20} />
      </div>
      <Label size="sm" color="strong900">
        Cooling Completed
      </Label>
    </div>
  ) : null;

  const currentStats =
    segment !== undefined && snapshots.length > 0 && !isComplete ? (
      <div className={styles.currentStats}>
        <div className={styles.statCard}>
          <div className={`${styles.statIcon} ${styles.internal}`}>
            <IconTemperature width={16} height={16} />
          </div>
          <Label size="sm" color="strong900">
            {getSnapshotInternalTemp(
              currentSnapshot!,
              segment.deviceType
            ).toFixed(2)}
            °C
          </Label>
          <Text size="xs" color="sub600">
            Internal
          </Text>
        </div>
        <div className={styles.statCard}>
          <div className={`${styles.statIcon} ${styles.external}`}>
            <IconSun width={16} height={16} />
          </div>
          <Label size="sm" color="strong900">
            {getSnapshotExternalTemp(
              currentSnapshot!,
              segment.deviceType
            ).toFixed(2)}
            °C
          </Label>
          <Text size="xs" color="sub600">
            External
          </Text>
        </div>
        <div className={styles.statCard}>
          <div className={`${styles.statIcon} ${styles.battery}`}>
            <IconLightning width={16} height={16} />
          </div>
          <Label size="sm" color="strong900">
            {currentBattery}%
          </Label>
          <Text size="xs" color="sub600">
            Battery
          </Text>
        </div>
      </div>
    ) : null;

  return (
    <>
      <InfoCard
        title="Cooling Details"
        headerAction={
          <Button
            type={'neutral'}
            size={'xxs'}
            style={'stroke'}
            label="View Profile"
            onClick={() => setShowModal(true)}
          />
        }
      >
        {banner}
        {tags
          .filter(
            (tag) =>
              (!tag.artycOnly || (tag.artycOnly && isArtycViewer)) &&
              tag.value !== undefined
          )
          .map((tag, idx) => (
            <div key={`tag-${idx}`} className={styles.row}>
              <Label span size="sm" color="strong900">
                {tag.label}:&nbsp;
              </Label>
              <Text span size="sm" color="sub600">
                {tag.value}
              </Text>
            </div>
          ))}
        {currentStats}
      </InfoCard>
      <DataModal
        show={showModal}
        onClose={() => setShowModal(false)}
        title="Shipping Profile"
      >
        <div className={styles.modalContent}>
          <TempManagementSection
            segment={segment}
            shippingProfile={shippingProfile}
          />
        </div>
      </DataModal>
    </>
  );
};

export default CoolingDetailsCard;
