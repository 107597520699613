import { genericFetch, genericPatch, genericPost } from './api';
import { Auth } from '../state/auth/types';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ShipmentTypeEnum, ShippoCarrierEnum } from '../state/shipments/types';
import {
  AddressEntity,
  NewOrExistingAddressEntity,
} from '../state/addresses/types';
import { OrderEntity, OrderStatus } from '../state/orders/types';

const ARTYC_ORDER_URL = '/artyc/orders';
const COMPANY_ORDER_URL = (auth: Auth | null) =>
  `/api/v1/company/${auth?.companyId}/orders`;

export interface ShipmentBody {
  leg: number;
  shipmentType: ShipmentTypeEnum;
  carrier?: ShippoCarrierEnum;
  trackingNumber?: string;
  origin?: NewOrExistingAddressEntity;
  destination?: NewOrExistingAddressEntity;
}

export interface CreateOrderRequestBody {
  company: string;
  shippingProfile: string;
  expectedDeliveryDate?: string;
  poNumber?: string;
  customId?: string;
  device?: string;
  shipments: ShipmentBody[];
}

// assume we're only ever updating the destination address
// for now frontend can't set lat/long, but it may in the future
export type UpdateOrderShipmentBody = Omit<
  ShipmentBody,
  'leg' | 'shipmentType' | 'origin' | 'destination'
> & {
  destination: Omit<
    AddressEntity,
    'country' | 'addressType' | 'latitude' | 'longitude'
  >;
};

export interface UpdateOrderRequestBody {
  expectedDeliveryDate?: string;
  device?: string;
  shipments: {
    [shipmentId: string]: UpdateOrderShipmentBody;
  };
}

const OrdersApi = {
  getOrder: async (
    axios: AxiosInstance,
    auth: Auth | null,
    orderId: string
  ): Promise<OrderEntity> => {
    const response: OrderEntity = await genericFetch(
      axios,
      auth,
      `${ARTYC_ORDER_URL}/${orderId}`,
      `${COMPANY_ORDER_URL(auth)}/${orderId}`
    );
    return response;
  },
  createOrder: async (
    axios: AxiosInstance,
    auth: Auth | null,
    orderData: CreateOrderRequestBody
  ): Promise<{ journeyId: string; orderId: string }> => {
    const response: AxiosResponse<{ journeyId: string; orderId: string }> =
      await genericPost(
        axios,
        auth,
        ARTYC_ORDER_URL,
        COMPANY_ORDER_URL(auth),
        orderData
      );

    return response.data;
  },
  updateOrder: async (
    axios: AxiosInstance,
    auth: Auth | null,
    orderId: string,
    orderUpdate: UpdateOrderRequestBody
  ): Promise<{ journeyId: string; orderId: string }> => {
    const response: AxiosResponse<{ journeyId: string; orderId: string }> =
      await genericPatch(
        axios,
        auth,
        `${ARTYC_ORDER_URL}/${orderId}`,
        `${COMPANY_ORDER_URL(auth)}/${orderId}`,
        orderUpdate
      );
    return response.data;
  },
  updateOrderStatus: async (
    axios: AxiosInstance,
    auth: Auth | null,
    orderId: string,
    status: OrderStatus
  ): Promise<void> => {
    return genericPatch(
      axios,
      auth,
      `${ARTYC_ORDER_URL}/${orderId}/status`,
      `${COMPANY_ORDER_URL(auth)}/${orderId}/status`,
      {
        status,
      }
    );
  },
};

export default OrdersApi;
