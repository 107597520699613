import { Chart, TooltipModel } from 'chart.js';
import { SegmentSnapshotEntity } from '../../../../../state/segmentSnapshots/types';
import { formatXAxisValue } from './chartOptions';
import { getSnapshotInternalTemp } from '../../../../../utils/segmentSnapshot/data';
import { DeviceType } from '../../../../../state/devices/types';
// Add this interface to handle the chart context
interface ChartContext {
  chart: Chart;
  tooltip: TooltipModel<'line'>;
}

export interface RangeTooltipContext {
  fromTimestampMs: number;
  toTimestampMs: number;
}

const getOrCreateTooltip = (chart: Chart) => {
  let tooltipEl = chart.canvas.parentNode?.querySelector('#tooltip') as
    | HTMLDivElement
    | undefined
    | null;

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'tooltip';
    tooltipEl.style.background = '#252525';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode?.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (
  context: ChartContext,
  xAxisType: 'time' | 'duration' | 'timeDuration',
  startDate?: Date,
  rangeContext?: RangeTooltipContext
) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement('thead');

    if (rangeContext !== undefined) {
      // sparkline range tooltip
      const fromTr = document.createElement('tr');
      fromTr.style.borderWidth = '0';
      const fromTh = document.createElement('th');
      fromTh.style.borderWidth = '0';
      fromTh.style.fontSize = '12px';
      fromTh.style.paddingBottom = '4px';
      const fromText = document.createTextNode(
        `From: ${formatXAxisValue(
          rangeContext.fromTimestampMs,
          xAxisType,
          startDate
        )}`
      );
      fromTh.appendChild(fromText);
      fromTr.appendChild(fromTh);
      tableHead.appendChild(fromTr);

      const toTr = document.createElement('tr');
      toTr.style.borderWidth = '0';
      const toTh = document.createElement('th');
      toTh.style.borderWidth = '0';
      toTh.style.fontSize = '12px';
      toTh.style.paddingBottom = '4px';
      const toText = document.createTextNode(
        `To: ${formatXAxisValue(
          rangeContext.toTimestampMs,
          xAxisType,
          startDate
        )}`
      );
      toTh.appendChild(toText);
      toTr.appendChild(toTh);
      tableHead.appendChild(toTr);
    } else {
      // Regular tooltip
      const xValue = tooltip.dataPoints?.[0]?.parsed?.x;
      const formattedTime = formatXAxisValue(xValue, xAxisType, startDate);

      titleLines.forEach(() => {
        const tr = document.createElement('tr');
        tr.style.borderWidth = '0';
        const th = document.createElement('th');
        th.style.borderWidth = '0';
        th.style.fontSize = '12px';
        th.style.paddingBottom = '4px';
        const text = document.createTextNode(formattedTime);
        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });
    }

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');
      // @ts-ignore
      span.style.background = colors.borderColor;
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '10px';
      span.style.borderRadius = '3px';
      span.style.display = 'inline-block';

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = '0';

      const td = document.createElement('td');
      td.style.borderWidth = '0';
      td.style.fontSize = '12px';
      td.style.textAlign = 'left';

      //@ts-ignore
      const text = document.createTextNode(body);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot?.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot?.appendChild(tableHead);
    tableRoot?.appendChild(tableBody);
  }

  // For sparkline source, position tooltip is already handled
  if (rangeContext === undefined) {
    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 10 + 'px';
  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.padding =
    tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  tooltipEl.style.width = '155px';
};

export const triggerTooltip = (
  chart: Chart | null,
  snapshot: SegmentSnapshotEntity,
  snapshotIndex: number,
  deviceType: DeviceType
) => {
  if (!chart?.tooltip) {
    return;
  }

  const position = {
    x: snapshot.secSinceStart,
    y: getSnapshotInternalTemp(snapshot, deviceType),
  };

  const visibleDatasets =
    chart.legend?.legendItems
      ?.filter((item) => !item.hidden)
      .map((item) => item.datasetIndex || 0) ?? [];

  const activeElements = visibleDatasets.map((datasetIndex) => ({
    datasetIndex,
    index: snapshotIndex,
  }));

  requestAnimationFrame(() => {
    if (chart.tooltip) {
      // show the tooltip
      chart.tooltip.setActiveElements(activeElements, position);
      chart.update('none');

      // simulate a mousemove event to trigger the hover state on the graph
      // to get the hover points to show up
      const canvasRect = chart.canvas.getBoundingClientRect();
      const simulatedEvent = new MouseEvent('mousemove', {
        clientX: canvasRect.left + chart.tooltip.caretX,
        clientY: canvasRect.top + chart.tooltip.caretY + 10,
      });
      chart.canvas.dispatchEvent(simulatedEvent);
    }
  });
};
