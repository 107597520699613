import { formatGraphTime } from '../../../../../utils/dateUtil';
import { SegmentMapPopupProps, SegmentMapPopupData } from './types';

const formatTemperature = (min: number, max: number) => {
  return min === max ? `${min}°C` : `${min}°C - ${max}°C`;
};

export const useSegmentMapPopup = (
  properties: SegmentMapPopupProps
): SegmentMapPopupData => {
  const {
    indexes,
    timestamps,
    internalTemps,
    externalTemps,
    segmentId,
    isArtycViewer,
  } = properties;

  const timestampRanges = [];
  const indexRanges = [];
  let rangeStart = 0;

  for (let i = 0; i < indexes.length; i++) {
    if (i === indexes.length - 1 || indexes[i] + 1 !== indexes[i + 1]) {
      let timeRangeSubString: string;
      let indexRangeSubString: string;
      if (rangeStart === i) {
        timeRangeSubString = `${formatGraphTime(timestamps[i])}`;
        indexRangeSubString = `${indexes[i]}`;
      } else {
        timeRangeSubString = `${formatGraphTime(
          timestamps[rangeStart]
        )} - ${formatGraphTime(timestamps[i])}`;
        indexRangeSubString = `${indexes[rangeStart]} - ${indexes[i]}`;
      }
      timestampRanges.push(timeRangeSubString);
      indexRanges.push(indexRangeSubString);
      rangeStart++;
    }
  }

  const timeRangeString = timestampRanges.join(', ');
  const indexRangeString = indexRanges.join(', ');

  const internalTempsRanges = internalTemps.reduce(
    (acc, curr) => {
      acc.minInternal = Math.min(acc.minInternal, curr);
      acc.maxInternal = Math.max(acc.maxInternal, curr);
      return acc;
    },
    {
      minInternal: Infinity,
      maxInternal: -Infinity,
    }
  );

  const externalTempsRanges = externalTemps.reduce(
    (acc, curr) => {
      acc.minExternal = Math.min(acc.minExternal, curr);
      acc.maxExternal = Math.max(acc.maxExternal, curr);
      return acc;
    },
    {
      minExternal: Infinity,
      maxExternal: -Infinity,
    }
  );

  return {
    timeRange: timeRangeString,
    internalTemp: formatTemperature(
      internalTempsRanges.minInternal,
      internalTempsRanges.maxInternal
    ),
    externalTemp: formatTemperature(
      externalTempsRanges.minExternal,
      externalTempsRanges.maxExternal
    ),
    segmentId,
    snapshotCount: indexes.length,
    indices: indexRangeString,
    isArtycViewer,
  };
};
