import { MapPoint, MapPointGroup } from '../../../../../components/map/Map';
import { isValidLatLong } from '../../../../../components/map/mapBounds';
import { DeviceType } from '../../../../../state/devices/types';
import {
  SegmentEntity,
  SegmentStatus,
} from '../../../../../state/segments/types';
import { SegmentSnapshotEntity } from '../../../../../state/segmentSnapshots/types';
import { ShipmentEntity } from '../../../../../state/shipments/types';
import {
  getSnapshotExternalTemp,
  getSnapshotInternalTemp,
} from '../../../../../utils/segmentSnapshot/data';
import SegmentMapPopup from './SegmentMapPopup';
import { SegmentMapPopupProps } from './types';

export interface SegmentMapPointProperties {
  pointIndex: number;
  indexes: number[];
  timestamps: string[];
  internalTemps: number[];
  externalTemps: number[];
  secSinceStarts: number[];
}

export const groupSnapshotsByPoints = (
  snapshots: SegmentSnapshotEntity[],
  deviceType: DeviceType
): MapPoint[] => {
  const pointsMap: { [key: string]: MapPoint } = {};
  let currentPointIndex = 0;

  snapshots.forEach((snapshot, index) => {
    const { location, timestamp, secSinceStart } = snapshot;

    if (location && isValidLatLong(location.lat, location.long)) {
      const coordinates: [number, number] = [location.long, location.lat];
      const coordKey = coordinates.join(',');

      const internalTemp = getSnapshotInternalTemp(snapshot, deviceType);
      const externalTemp = getSnapshotExternalTemp(snapshot, deviceType);

      if (!pointsMap[coordKey]) {
        const properties: SegmentMapPointProperties = {
          pointIndex: currentPointIndex++,
          indexes: [index],
          timestamps: [timestamp],
          secSinceStarts: [secSinceStart],
          internalTemps: [internalTemp],
          externalTemps: [externalTemp],
        };

        pointsMap[coordKey] = {
          latitude: location.lat,
          longitude: location.long,
          properties,
        };
      } else {
        pointsMap[coordKey].properties!.timestamps.push(timestamp);
        pointsMap[coordKey].properties!.indexes.push(index);
        pointsMap[coordKey].properties!.internalTemps.push(internalTemp);
        pointsMap[coordKey].properties!.externalTemps.push(externalTemp);
        pointsMap[coordKey].properties!.secSinceStarts.push(secSinceStart);
      }
    }
  });

  return Object.values(pointsMap).sort(
    (a, b) => (a.properties?.pointIndex ?? 0) - (b.properties?.pointIndex ?? 0)
  );
};

const mkPointsAndLines = (
  segmentId: string,
  isArtycViewer: boolean,
  mapPoints: MapPoint[],
  onHovered: (index: number) => void
): MapPointGroup[] => {
  return [
    {
      groupId: `lines${segmentId}`,
      geometry: 'LineString',
      points: mapPoints,
      groupStyle: {
        id: `lines${segmentId}`,
        type: 'line',
        source: `lines${segmentId}`,
        paint: {
          'line-color': '#2970ff',
          'line-width': 2,
        },
      },
    },
    {
      groupId: `points${segmentId}`,
      geometry: 'Point',
      points: mapPoints,
      groupStyle: {
        id: `points${segmentId}`,
        type: 'circle',
        source: `points${segmentId}`,
        paint: {
          'circle-radius': 4,
          'circle-color': '#2970ff',
        },
      },
      onHover: {
        popupComponent: {
          component: SegmentMapPopup,
          componentProps: (properties) => {
            // array features.properties get stringified so we need to parse them first
            const props: SegmentMapPopupProps = {
              indexes: JSON.parse(properties?.indexes.toString()),
              timestamps: JSON.parse(properties?.timestamps.toString()),
              internalTemps: JSON.parse(properties?.internalTemps.toString()),
              externalTemps: JSON.parse(properties?.externalTemps.toString()),
              isArtycViewer,
              segmentId,
              onHovered,
            };
            return props;
          },
        },
      },
    },
  ];
};

const mkMarkerPoint = (
  latlong: LatLong,
  id: string,
  backgroundColor: string
): MapPointGroup[] => {
  if (latlong === undefined) {
    return [];
  }

  const point = {
    latitude: latlong[0],
    longitude: latlong[1],
    properties: {},
  };

  const backgroundLayer: MapPointGroup = {
    groupId: `${id}-bg`,
    geometry: 'Point',
    points: [point],
    groupStyle: {
      id: `${id}-bg`,
      type: 'circle',
      source: `${id}-bg`,
      paint: {
        'circle-radius': 12,
        'circle-color': backgroundColor,
        'circle-radius-transition': { duration: 0 },
      },
    },
  };

  const pinLayer: MapPointGroup = {
    groupId: id,
    geometry: 'Point',
    points: [point],
    groupStyle: {
      id: id,
      type: 'symbol',
      source: id,
      layout: {
        'icon-image': 'MapboxPin',
        'icon-size': 1,
      },
    },
  };

  return [backgroundLayer, pinLayer];
};

type LatLong = [number, number] | undefined;
type MarkerPoints = { start: LatLong; here: LatLong; end: LatLong };
const mkMarkerPoints = (markerPoints: MarkerPoints): MapPointGroup[] => {
  const { start, here, end } = markerPoints;
  const startPoint = start ? mkMarkerPoint(start, 'start', '#2970ff') : [];
  const endPoint = end ? mkMarkerPoint(end, 'end', '#1FC16B') : [];

  const herePoint: MapPointGroup[] = here
    ? [
        {
          groupId: 'here',
          geometry: 'Point',
          points: [
            {
              latitude: here[0],
              longitude: here[1],
              properties: {},
            },
          ],
          groupStyle: {
            id: `here`,
            type: 'circle',
            source: `here`,
            paint: {
              'circle-radius': 4,
              'circle-color': '#f5f7fa',
              'circle-stroke-color': '#181B25',
              'circle-stroke-width': 1,
            },
          },
        },
      ]
    : [];

  return [...startPoint, ...herePoint, ...endPoint];
};

export const calculateMarkerPoints = (
  segment: SegmentEntity,
  shipment: ShipmentEntity,
  snapshots: SegmentSnapshotEntity[]
): MarkerPoints => {
  const firstSnapshotLocation = snapshots.find(
    (s) =>
      s.location !== null &&
      s.location !== undefined &&
      isValidLatLong(s.location.lat, s.location.long)
  )?.location;
  const lastSnapshotLocation = [...snapshots]
    .reverse()
    .find(
      (s) =>
        s.location !== null &&
        s.location !== undefined &&
        isValidLatLong(s.location.lat, s.location.long)
    )?.location;

  let start: LatLong;
  let here: LatLong;
  let end: LatLong;

  // start is origin or first snapshot
  if (
    shipment.origin &&
    shipment.origin.latitude &&
    shipment.origin.longitude
  ) {
    start = [shipment.origin.latitude, shipment.origin.longitude];
  } else if (firstSnapshotLocation) {
    start = [firstSnapshotLocation.lat, firstSnapshotLocation.long];
  } else {
    start = undefined;
  }

  // end is destination or if ended, last snapshot
  if (
    shipment.destination &&
    shipment.destination.latitude &&
    shipment.destination.longitude
  ) {
    end = [shipment.destination.latitude, shipment.destination.longitude];
  } else if (segment.status === SegmentStatus.COMPLETE) {
    end = lastSnapshotLocation
      ? [lastSnapshotLocation.lat, lastSnapshotLocation.long]
      : undefined;
  }

  // we only want to show here if segment's not over yet
  if (segment.status !== SegmentStatus.COMPLETE) {
    here = lastSnapshotLocation
      ? [lastSnapshotLocation.lat, lastSnapshotLocation.long]
      : undefined;
  }

  return { start, here, end };
};

export const mkPointGroups = (
  segment: SegmentEntity,
  shipment: ShipmentEntity,
  snapshots: SegmentSnapshotEntity[],
  mapPoints: MapPoint[],
  isArtycViewer: boolean,
  onHovered: (index: number) => void
): MapPointGroup[] => {
  const pointsAndLines = mkPointsAndLines(
    segment._id,
    isArtycViewer,
    mapPoints,
    onHovered
  );
  const markerPoints = calculateMarkerPoints(segment, shipment, snapshots);

  return [...pointsAndLines, ...mkMarkerPoints(markerPoints)];
};
