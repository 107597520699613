import React from 'react';
import StatusBadge from '../../../../aurora/components/StatusBadge/StatusBadge';
import { OrderStatus } from '../../../../state/orders/types';

interface Props {
  status: OrderStatus;
}
const OrderStatusBadge = ({ status }: Props) => {
  if (status === OrderStatus.PENDING) {
    return <StatusBadge content="Pending" status="gray" />;
  } else if (status === OrderStatus.IN_PROGRESS) {
    return <StatusBadge content="In Progress" status="blue" />;
  } else if (status === OrderStatus.CANCELLED) {
    return <StatusBadge content="Cancelled" status="orange" />;
  } else {
    return <StatusBadge content="Fulfilled" status="green" />;
  }
};

export default OrderStatusBadge;
