import React, { useRef, useState, useEffect } from 'react';
import useLogout from '../../../hooks/useLogout';
import TextInput from '../../../aurora/components/TextInput/TextInput';
import { Button } from '../../../aurora/components/Button/legacy/Button';
import { IconWarningCircle, IconCheckCircleFill } from '../../../aurora/icons';
import styles from '../../../components/layout/Splash.module.scss';

interface RequirementCheckProps {
  requirement: boolean;
  text: string;
}

const RequirementCheck = ({ requirement, text }: RequirementCheckProps) => {
  return (
    <div className={styles.passwordRequirement}>
      <div className={requirement ? styles.validIcon : styles.invalidIcon}>
        <IconCheckCircleFill
          className={styles.validIcon}
          width={20}
          height={20}
        />
      </div>
      <span className={requirement ? styles.validText : styles.invalidText}>
        {text}
      </span>
    </div>
  );
};

interface Props {
  token: string | undefined;
  submitPassword: (
    token: string | undefined,
    password: string
  ) => Promise<void>;
  handleSubmitError: (error: any) => void;
}

const SetPasswordForm = ({
  token,
  submitPassword,
  handleSubmitError,
}: Props) => {
  const passwordRef = useRef<HTMLInputElement>(null);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [passwordHasNumber, setPasswordHasNumber] = useState(false);
  const [passwordIsLongEnough, setPasswordIsLongEnough] = useState(false);

  const [showValidityError, setShowValidityError] = useState(false);
  const [showMatchError, setShowMatchError] = useState(false);

  const passwordValidityTimeout = useRef<number | null>(null);
  const passwordsMatchTimeout = useRef<number | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const passwordIsValid = passwordHasNumber && passwordIsLongEnough;
  const passwordsMatch = password === confirmPassword;

  const logout = useLogout();

  // sets focus to password text input
  useEffect(() => {
    passwordRef.current?.focus();
    logout();
  }, [logout]);

  // updates the password requirement checks
  useEffect(() => {
    const hasNumber = /\d/.test(password);
    const isLongEnough = password.length >= 8;
    setShowValidityError(false);
    setPasswordHasNumber(hasNumber);
    setPasswordIsLongEnough(isLongEnough);
  }, [password]);

  useEffect(() => {
    setShowMatchError(false);
  }, [confirmPassword]);

  // sets password validity error message using timeout
  useEffect(() => {
    if ((!passwordIsLongEnough || !passwordHasNumber) && password.length > 0) {
      if (passwordValidityTimeout.current !== null) {
        clearTimeout(passwordValidityTimeout.current);
      }

      const timeoutId = window.setTimeout(() => {
        setShowValidityError(true);
      }, 1000);
      passwordValidityTimeout.current = timeoutId;
    } else {
      setShowValidityError(false);
    }

    return () => {
      if (passwordValidityTimeout !== null) {
        clearTimeout(passwordValidityTimeout.current ?? undefined);
      }
    };
  }, [passwordHasNumber, passwordIsLongEnough, password]);

  // sets password match error message using timeout
  useEffect(() => {
    if (password !== confirmPassword && confirmPassword.length > 0) {
      if (passwordsMatchTimeout.current !== null) {
        clearTimeout(passwordsMatchTimeout.current);
      }

      const timeoutId = window.setTimeout(() => {
        setShowMatchError(true);
      }, 1000);
      passwordsMatchTimeout.current = timeoutId;
    } else {
      setShowMatchError(false);
    }

    return () => {
      if (passwordsMatchTimeout !== null) {
        clearTimeout(passwordsMatchTimeout.current ?? undefined);
      }
    };
  }, [password, confirmPassword]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!passwordIsValid || !passwordsMatch) {
      setIsSubmitting(false);
      return;
    }
    try {
      await submitPassword(token, password.trim());
    } catch (err: any) {
      handleSubmitError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const styledIcon = () => (
    <div className={styles.errorIcon}>
      <IconWarningCircle className={styles.errorIcon} width={20} height={20} />
    </div>
  );

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formInputsWithValidation}>
          <div>
            <TextInput
              variant={'filter'}
              ref={passwordRef}
              placeholder={'Enter password'}
              label={'Password'}
              managedText={password}
              type="password"
              onChange={(newPassword) => setPassword(newPassword)}
              className={showValidityError ? styles.textInputError : ''}
              error={showValidityError}
              iconRight={showValidityError ? styledIcon : undefined}
            />
            <div
              className={`${styles.textInputErrMsg} ${
                showValidityError ? '' : styles.hidden
              }`}
            >
              Password does not meet requirements.
            </div>
          </div>
          <div>
            <TextInput
              variant={'filter'}
              placeholder={'Confirm password'}
              label={'Confirm password'}
              type="password"
              managedText={confirmPassword}
              onChange={(newConfirmPassword) =>
                setConfirmPassword(newConfirmPassword)
              }
              className={showMatchError ? styles.textInputError : ''}
              error={showMatchError}
              iconRight={showMatchError ? styledIcon : undefined}
            />
            <div
              className={`${styles.textInputErrMsg} ${
                showMatchError ? '' : styles.hidden
              }`}
            >
              Passwords do not match.
            </div>
          </div>
        </div>
        <div className={styles.passwordRequirementsContainer}>
          <RequirementCheck
            requirement={passwordIsLongEnough}
            text={'Must be at least 8 characters'}
          />
          <RequirementCheck
            requirement={passwordHasNumber}
            text={'Must contain one number'}
          />
        </div>
        <Button
          className={styles.formButton}
          variant="primary"
          label="Get started"
          disabled={isSubmitting || !passwordsMatch || !passwordIsValid}
          onClick={(e) => e}
        />
      </form>
    </>
  );
};

export default SetPasswordForm;
