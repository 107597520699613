import React from 'react';
import { ActiveDataPoint, Chart as ChartJS } from 'chart.js';
import styles from './XAxisRangeSelector.module.scss';
import { TimeRange } from './SnapshotsGraphContainer';
import { RangeTooltipContext, externalTooltipHandler } from './Tooltip';

// position is a decimal between 0 and 1 representing
// where the point is along the line of datapoints
// 0 = at the start, 1 = at the end
interface RangeState {
  startPosition: number;
  endPosition: number;
  isDragging: boolean;
  isResizing: 'start' | 'end' | null;
}
const createSparkline = (
  points: { timestampMs: number; internalTemp: number }[],
  width: number,
  height: number
): string => {
  if (points.length === 0) {
    return '';
  }

  const paddingX = width * 0.01;
  const usableWidth = width - paddingX * 2;

  const minX = points[0].timestampMs;
  const maxX = points[points.length - 1].timestampMs;
  const xRange = maxX - minX;

  const minY = Math.min(...points.map((p) => p.internalTemp));
  const maxY = Math.max(...points.map((p) => p.internalTemp));
  const yRange = maxY - minY || 1;

  const yPadding = yRange * 0.05;
  const paddedMinY = minY - yPadding;
  const paddedYRange = yRange + yPadding * 2;

  const scaled = points.map((p) => ({
    x: paddingX + ((p.timestampMs - minX) / xRange) * usableWidth,
    y: height - ((p.internalTemp - paddedMinY) / paddedYRange) * height,
  }));

  return `M ${scaled[0].x},${scaled[0].y} ${scaled
    .slice(1)
    .map((p) => `L ${p.x},${p.y}`)
    .join(' ')}`;
};

interface Props {
  data: { timestampMs: number; internalTemp: number }[];
  chartRef: React.RefObject<ChartJS>;
  showDateAsDuration: boolean;
  onRangeSelect?: (range: TimeRange | undefined) => void;
}
const XAxisRangeSelector: React.FC<Props> = ({
  data,
  chartRef,
  showDateAsDuration,
  onRangeSelect,
}) => {
  const [selectedRange, setSelectedRange] = React.useState<RangeState>({
    startPosition: 0,
    endPosition: 1,
    isDragging: false,
    isResizing: null,
  });

  const fullDataRange: TimeRange = {
    startMs: data[0].timestampMs,
    endMs: data[data.length - 1].timestampMs,
  };

  // position (0 to 1) -> timestamp along the full range
  const positionToTimestamp = (position: number) => {
    return (
      fullDataRange.startMs +
      (fullDataRange.endMs - fullDataRange.startMs) * position
    );
  };

  // timestamp -> data idx (might not be one on the exact timestamp)
  const findNearestDataIndex = (timestampMs: number): number => {
    return data.reduce((nearestIdx, point, currentIdx) => {
      if (
        Math.abs(point.timestampMs - timestampMs) <
        Math.abs(data[nearestIdx].timestampMs - timestampMs)
      ) {
        return currentIdx;
      }
      return nearestIdx;
    }, 0);
  };

  const positionsToDataIndices = (
    startPosition: number,
    endPosition: number
  ) => {
    const startTimestamp = positionToTimestamp(startPosition);
    const endTimestamp = positionToTimestamp(endPosition);

    const fromIndex = findNearestDataIndex(startTimestamp);
    const toIndex = findNearestDataIndex(endTimestamp);

    return {
      fromIndex,
      toIndex,
    };
  };

  const setTooltip = (
    rangeContext: RangeTooltipContext | undefined,
    datapoints: ActiveDataPoint[]
  ) => {
    if (!chartRef.current) {
      return;
    }

    const startDate =
      data.length > 0 ? new Date(data[0].timestampMs) : new Date();
    const tooltipConfig = {
      enabled: false,
      position: 'nearest' as const,
      external: (ctx: any) =>
        externalTooltipHandler(
          ctx,
          showDateAsDuration ? 'timeDuration' : 'time',
          startDate,
          rangeContext
        ),
    };

    if (chartRef.current.options.plugins) {
      chartRef.current.options.plugins.tooltip = tooltipConfig;
      chartRef.current.update('none');
    }

    if (chartRef.current.tooltip) {
      const position = {
        x:
          chartRef.current.chartArea.left +
          (chartRef.current.chartArea.right - chartRef.current.chartArea.left) /
            2,
        y:
          chartRef.current.chartArea.top +
          (chartRef.current.chartArea.bottom - chartRef.current.chartArea.top) /
            2,
      };
      chartRef.current.tooltip.setActiveElements(datapoints, position);
      chartRef.current.update('none');
    }
  };

  const setRangeTooltip = (fromIndex: number, toIndex: number) => {
    const rangeContext: RangeTooltipContext = {
      fromTimestampMs: data[fromIndex].timestampMs,
      toTimestampMs: data[toIndex].timestampMs,
    };

    setTooltip(rangeContext, [
      { datasetIndex: 1, index: fromIndex },
      { datasetIndex: 1, index: toIndex },
    ]);
  };

  // show the tooltip on hover
  const handleSparklineHover = () => {
    const indices = positionsToDataIndices(
      selectedRange.startPosition,
      selectedRange.endPosition
    );
    if (!indices) {
      return;
    }

    setRangeTooltip(indices.fromIndex, indices.toIndex);
  };

  // clicking sparkline starts dragging new bars to set start + end from scratch
  const handleSparklineMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    // minus left padding
    const x = e.clientX - rect.left - 20;
    // left + right padding
    const width = rect.width - 44;
    // minus top padding
    const y = e.clientY - rect.top - 5;

    if (x < 0 || x > width || y < 0) {
      return;
    }

    const position = x / width;

    setSelectedRange({
      startPosition: position,
      endPosition: position,
      isDragging: true,
      isResizing: null,
    });
  };

  // clicking resize bar starts dragging existing selection to adjust start/end of bar being dragged
  const handleResizeBarMouseDown =
    (initialPosition: 'start' | 'end') => (e: React.MouseEvent) => {
      e.stopPropagation();
      setSelectedRange((prev) => ({
        ...prev,
        isResizing: initialPosition,
        isDragging: true,
      }));
    };

  const handleSparklineMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!chartRef.current || !selectedRange.isDragging) {
      return handleSparklineHover();
    }

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left - 20; // minus left padding
    const width = rect.width - 44; // left + right padding
    const position = Math.max(0, Math.min(1, x / width));

    // Get the new start/end positions based on drag direction
    const newStart =
      position < selectedRange.startPosition
        ? position
        : selectedRange.startPosition;
    const newEnd =
      position > selectedRange.endPosition
        ? position
        : selectedRange.endPosition;

    setSelectedRange((prev) => {
      if (selectedRange.isResizing) {
        if (
          selectedRange.isResizing === 'start' &&
          position > prev.endPosition
        ) {
          // start bar crossed end bar - swap roles
          return {
            ...prev,
            startPosition: prev.endPosition,
            endPosition: position,
            isResizing: 'end',
          };
        } else if (
          selectedRange.isResizing === 'end' &&
          position < prev.startPosition
        ) {
          // end bar crossed start bar - swap roles
          return {
            ...prev,
            startPosition: position,
            endPosition: prev.startPosition,
            isResizing: 'start',
          };
        }
        // normal resize behavior
        return {
          ...prev,
          startPosition:
            selectedRange.isResizing === 'start'
              ? position
              : prev.startPosition,
          endPosition:
            selectedRange.isResizing === 'end' ? position : prev.endPosition,
        };
      } else {
        // initial drag behavior - update start if dragging left, end if dragging right
        return {
          ...prev,
          startPosition: newStart,
          endPosition: newEnd,
        };
      }
    });

    const indices = positionsToDataIndices(newStart, newEnd);
    if (!indices) {
      return;
    }

    setRangeTooltip(indices.fromIndex, indices.toIndex);
  };

  const resetState = () => {
    setSelectedRange((prev) => ({
      ...prev,
      isDragging: false,
      isResizing: null,
    }));

    // reset tooltip to main graph's
    setTooltip(undefined, []);
  };

  // set range
  const handleSparklineMouseUp = () => {
    if (
      selectedRange.isDragging &&
      selectedRange.startPosition !== selectedRange.endPosition
    ) {
      onRangeSelect?.({
        startMs: positionToTimestamp(
          Math.min(selectedRange.startPosition, selectedRange.endPosition)
        ),
        endMs: positionToTimestamp(
          Math.max(selectedRange.startPosition, selectedRange.endPosition)
        ),
      });
    }

    resetState();
  };

  // buffer the graph with a bit of padding so that we can
  // show the resize bars slightly offset from the graph
  const resizeBarWidth = 1;
  const sparklineWidth = 100 - resizeBarWidth * 2;
  return (
    <div
      className={styles.container}
      onMouseMove={handleSparklineMouseMove}
      onMouseLeave={handleSparklineMouseUp}
      onMouseDown={handleSparklineMouseDown}
      onMouseUp={handleSparklineMouseUp}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        className={styles.svg}
      >
        <path
          d={createSparkline(data, 100, 100)}
          className={styles.path}
          vectorEffect="non-scaling-stroke"
        />
        <rect
          x={
            1 +
            Math.min(selectedRange.startPosition, selectedRange.endPosition) *
              sparklineWidth
          }
          y={0}
          width={
            Math.abs(selectedRange.endPosition - selectedRange.startPosition) *
            sparklineWidth
          }
          height={100}
          className={styles.selectionRect}
        />
        <rect
          x={
            1 +
            Math.min(selectedRange.startPosition, selectedRange.endPosition) *
              sparklineWidth -
            resizeBarWidth / 2.0
          }
          y={20}
          width={resizeBarWidth}
          height={60}
          className={styles.resizeBar}
          onMouseDown={handleResizeBarMouseDown('start')}
        />
        <rect
          x={
            1 +
            Math.max(selectedRange.startPosition, selectedRange.endPosition) *
              sparklineWidth -
            resizeBarWidth / 2.0
          }
          y={20}
          width={resizeBarWidth}
          height={60}
          className={styles.resizeBar}
          onMouseDown={handleResizeBarMouseDown('end')}
        />
      </svg>
    </div>
  );
};

export default XAxisRangeSelector;
