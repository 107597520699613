import React from 'react';
import { Text } from '../../aurora/typography/Text/Text';
import { Button } from '../../aurora/components/Button/legacy/Button';
import { BaseTheme } from '../../aurora/theme/baseTheme';
import { IconWarningCircle, IconCheckCircle } from '../../aurora/icons';
import { SxProp, useSx } from 'dripsy';
import { useDripsyTheme } from 'dripsy';
import styles from './NotificationBanner.module.scss';

export type NotficationBannerPropsType = {
  title: string;
  content?: string;
  sxProps?: SxProp;
  variant: keyof BaseTheme['banner'];
  onContinue: () => void;
  onDismiss: () => void;
};

const NotificationBanner: React.FC<NotficationBannerPropsType> = ({
  title,
  content,
  sxProps,
  variant,
  onContinue,
  onDismiss,
}) => {
  const { theme } = useDripsyTheme();
  const banner = theme.banner as BaseTheme['banner'];
  const variantSx = banner[variant];

  const sx = useSx();

  const BannerIcon =
    variant === 'warning' ? IconWarningCircle : IconCheckCircle;

  return (
    <div
      style={{
        ...sx(variantSx),
        ...sx(sxProps ?? {}),
      }}
      className={styles.banner}
    >
      <div className={styles.mainContent}>
        <BannerIcon className={styles.bannerIcon} />
        <div className={styles.textContainer}>
          <Text className={`${styles.textSize} ${styles.title}`}>{title}</Text>
          <Text className={`${styles.textSize} ${styles.subtitle}`}>
            {content}
          </Text>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          variant="secondaryNoBorder"
          label="Continue"
          onClick={onContinue}
        />
        <Button
          variant="secondaryNoBorder"
          label="Dismiss"
          onClick={onDismiss}
        />
      </div>
    </div>
  );
};

export default NotificationBanner;
