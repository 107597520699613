import { add } from 'date-fns';
import React from 'react';
import DatePickerField from '../../../../components/forms/DatePickerField';
import { required } from '../../../../components/forms/validators';

interface Props {
  isArtycViewer: boolean;
}
const ExpectedDeliveryDate = ({ isArtycViewer }: Props) => {
  const now = new Date();
  const minDate = isArtycViewer ? now : add(now, { days: 7 });
  const isRequired = !isArtycViewer;

  const tooltip = isArtycViewer
    ? 'Date you expect the user to receive the shipment by'
    : 'Date you want the user to receive the shipment by. Please select a start date at least 7 days from today to allow for processing and preparation';

  return (
    <DatePickerField
      name="expectedDeliveryDate"
      label="Delivery Date"
      minDate={minDate}
      showOptional={!isRequired}
      validate={isRequired ? required : undefined}
      excludeButtons={true}
      tooltip={tooltip}
    />
  );
};

export default ExpectedDeliveryDate;
