import React, { useEffect, useState } from 'react';
import FleetViewPageHeader from './components/FleetViewPageHeader';
import FleetViewPageDetails from './components/panel/FleetViewPageDetails';
import styles from './FleetViewPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { setDevices } from '../../state/fleetView';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import DevicesApi from '../../api/devicesApi';
import { selectAuth } from '../../state/auth';
import FleetViewMap from './components/map/FleetViewMap';
import FleetViewMapOverlay from './components/map/FleetViewMapOverlay';

import {
  FleetViewFilter,
  FleetViewSort,
  FleetViewStatus,
} from '../../state/fleetView/types';
import { DeviceType } from '../../state/devices/types';

const FleetViewPage: React.FC<{}> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  const { searchQuery, filter, devices, sort, selectedCompany } = useSelector(
    (state: RootState) => state.fleetView
  );

  const listedDevices = devices
    .filter((device) => {
      // if you don't match the search query, don't show
      if (searchQuery && !device.serialNumber.includes(searchQuery)) {
        return false;
      }

      const statuses = Object.keys(FleetViewStatus);
      const includeStatus = statuses.some(
        (status) =>
          filter[String(status) as keyof typeof FleetViewFilter] &&
          device.deviceDeploymentStatus ===
            String(FleetViewStatus[status as keyof typeof FleetViewStatus])
      );

      const deviceTypes = Object.keys(DeviceType);
      const includeType = deviceTypes.some(
        (type) =>
          filter[String(type) as keyof typeof FleetViewFilter] &&
          device.deviceType ===
            String(DeviceType[type as keyof typeof DeviceType])
      );

      // if you don't match one of the filters, don't show
      return includeStatus && includeType;
    })
    .sort((a, b) => {
      if (sort === FleetViewSort.UPDATED_DESC) {
        const aUpdatedAt = a.lastKnownLocation
          ? new Date(a.lastKnownLocation.timestamp).getTime()
          : 0;
        const bUpdatedAt = b.lastKnownLocation
          ? new Date(b.lastKnownLocation.timestamp).getTime()
          : 0;
        return bUpdatedAt - aUpdatedAt;
      } else {
        const aDeployedAt = a.journey?.startTime
          ? new Date(a.journey.startTime).getTime()
          : 0;
        const bDeployedAt = b.journey?.startTime
          ? new Date(b.journey.startTime).getTime()
          : 0;

        if (sort === FleetViewSort.DEPLOYED_ASC) {
          return aDeployedAt - bDeployedAt;
        } else if (sort === FleetViewSort.DEPLOYED_DESC) {
          return bDeployedAt - aDeployedAt;
        }
        throw new Error('Invalid sort');
      }
    });

  const mappedDevices = listedDevices.filter(
    (device) => device.lastKnownLocation
  );

  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  useEffect(() => {
    const fetchDevices = async () => {
      setError(undefined);
      setIsLoading(true);
      try {
        const response = await DevicesApi.getFleetViewDevices(
          axiosPrivate,
          auth,
          selectedCompany
        );
        dispatch(setDevices(response));
      } catch (error: any) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchDevices();
  }, [dispatch, axiosPrivate, auth, selectedCompany]);

  return (
    <div className={styles.pageContainer}>
      <FleetViewPageHeader />
      <div className={styles.contents}>
        <FleetViewPageDetails
          devices={listedDevices}
          isLoading={isLoading}
          error={error}
        />
        {isLoading ? null : (
          <div className={styles.mapContainer}>
            <FleetViewMap mappedDevices={mappedDevices} />
            <FleetViewMapOverlay devices={mappedDevices} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FleetViewPage;
