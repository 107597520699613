import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuroraProvider } from '../../aurora/theme/AuroraProvider';

type PopupComponentProps<T> = {
  properties: T;
};

type PopupComponent<T> = React.ComponentType<PopupComponentProps<T>>;

export function renderPopup<T>(
  Component: PopupComponent<T>,
  properties: T
): HTMLDivElement {
  const popupNode = document.createElement('div');
  const root = createRoot(popupNode);
  root.render(
    <AuroraProvider>
      <Component properties={properties} />
    </AuroraProvider>
  );
  return popupNode;
}
