// initializing assets, rendering points, zooming to fit bounds

import mapboxgl from 'mapbox-gl';
import { MapAsset, MapPointGroup } from './Map';
import { boundOptions } from './mapBounds';
import { renderPointGroups, updatePointGroups } from './mapRendering';

export const initializeMap = (container: HTMLDivElement) => {
  const map = new mapboxgl.Map({
    container,
    style: 'mapbox://styles/mapbox/light-v11',
    center: [0, 0],
    zoom: 2,
  });

  map.addControl(
    new mapboxgl.NavigationControl({
      showCompass: false,
    })
  );

  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    maxWidth: '300px',
  });

  return { map, popup };
};

export const setupMap = (
  map: mapboxgl.Map,
  pointGroups: MapPointGroup[],
  popup: mapboxgl.Popup,
  assets?: MapAsset[]
) => {
  if (assets !== undefined) {
    assets.forEach((asset) => {
      map.loadImage(asset.path, (error, image) => {
        if (error) {
          throw error;
        }
        if (image) {
          map.addImage(asset.name, image);
        }
      });
    });
  }

  const allBounds = renderPointGroups(map, pointGroups, popup);
  if (
    allBounds[0][0] !== Infinity &&
    allBounds[0][1] !== Infinity &&
    allBounds[1][0] !== -Infinity &&
    allBounds[1][1] !== -Infinity
  ) {
    map.fitBounds(allBounds, boundOptions());
  }
};

// we don't want to update bounds on update or else zoom doesn't work properly
// if we'd ever want to update bounds, we'd need to do it in a way that doesn't interfere with zooming
export const updateMap = (
  map: mapboxgl.Map,
  pointGroups: MapPointGroup[],
  popup: mapboxgl.Popup
) => {
  updatePointGroups(map, pointGroups, popup);
};
