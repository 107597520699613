import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkButton } from '../../../../aurora/components/Button/LinkButton';
import { IconLock } from '../../../../aurora/icons';
import ActionButton from '../../../../components/table/ActionButton';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { selectAuth } from '../../../../state/auth';
import { markInProgressAction } from '../../../../state/orders';
import { OrderEntity, OrderStatus } from '../../../../state/orders/types';
import { AppDispatch } from '../../../../state/store';
import EditOrderButton from './EditOrderButton';
import FulfillOrderButton from './FulfillOrderButton';
import styles from './OrderTableAction.module.scss';

interface Props {
  order: OrderEntity;
  isArtycViewer: boolean;
}
const OrderTableAction = ({ order, isArtycViewer }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const auth = useSelector(selectAuth);
  const axios = useAxiosPrivate();

  if (order.status === OrderStatus.PENDING) {
    // TODO(order): add cancel button
    return (
      <div className={styles.action}>
        {isArtycViewer ? (
          <ActionButton
            icon={IconLock}
            onClick={() => {
              dispatch(
                markInProgressAction({
                  axios,
                  auth,
                  orderId: order._id,
                  status: OrderStatus.IN_PROGRESS,
                })
              );
            }}
            label="Completed Sales Order"
          />
        ) : null}
        <EditOrderButton order={order} isArtycViewer={isArtycViewer} />
      </div>
    );
  } else if (order.status === OrderStatus.IN_PROGRESS && isArtycViewer) {
    return (
      <div className={styles.action}>
        <FulfillOrderButton order={order} isArtycViewer={isArtycViewer} />
      </div>
    );
  }

  return (
    <div className={styles.action}>
      <LinkButton
        href={`/portal/journey/${order.journeyId}`}
        label="View Journey"
        style="primary"
        size="sm"
      />
    </div>
  );
};

export default OrderTableAction;
