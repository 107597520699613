import React, { useEffect } from 'react';
import styles from './SegmentMapPopup.module.scss';
import { SegmentMapPopupProps } from './types';
import { useSegmentMapPopup } from './useSegmentMapPopup';

const SegmentMapPopup = ({
  properties,
}: {
  properties: SegmentMapPopupProps;
}) => {
  const { onHovered, indexes } = properties;
  useEffect(() => {
    if (onHovered && indexes) {
      onHovered(indexes[0]);
    }
  }, [onHovered]);

  const {
    timeRange,
    internalTemp,
    externalTemp,
    segmentId,
    snapshotCount,
    indices,
    isArtycViewer,
  } = useSegmentMapPopup(properties);

  return (
    <div className={styles.popupContent}>
      <div className={styles.row}>
        <span className={styles.label}>Timestamp:</span>
        <span className={styles.text}>{timeRange}</span>
      </div>

      <div className={styles.row}>
        <span className={styles.label}>
          {isArtycViewer ? 'Heat Sink' : 'External'} Temp:
        </span>
        <span className={styles.text}>{externalTemp}</span>
      </div>

      <div className={styles.row}>
        <span className={styles.label}>
          {isArtycViewer ? 'Sleeve' : 'Internal'} Temp:
        </span>
        <span className={styles.text}>{internalTemp}</span>
      </div>

      {isArtycViewer && (
        <>
          <div className={styles.row}>
            <span className={styles.label}>Segment:</span>
            <span className={styles.text}>{segmentId}</span>
          </div>

          <div className={styles.row}>
            <span className={styles.label}>Snapshot Count:</span>
            <span className={styles.text}>{snapshotCount}</span>
          </div>

          <div className={styles.row}>
            <span className={styles.label}>Indices:</span>
            <span className={styles.text}>{indices}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default SegmentMapPopup;
