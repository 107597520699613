import React, { useEffect, useState } from 'react';
import DataTableContainer from '../../components/table/DataTableContainer';
import {
  ColumnsConfig,
  EmptyConfig,
  SlideoutConfig,
  TopConfig,
} from '../../components/table/tableConfig';
import { IconShipment } from '../../aurora/icons';
import PageHeader from '../../components/layout/PageHeader';
import { useSelector } from 'react-redux';
import { isArtyc, selectAuth } from '../../state/auth';
import { formatDate, formatDateTime } from '../../utils/dateUtil';
import CaasOrderFormSlideout from './components/orderForm/CaasOrderFormSlideout';
import {
  loadOrders,
  resetPage,
  setCurrentPage,
  sortOrders,
} from '../../state/orders';
import { OrderEntity } from '../../state/orders/types';
import OrderStatusBadge from './components/orderTable/OrderStatusBadge';
import OrderTableAction from './components/orderTable/OrderTableAction';
import OrderInfoSlideout from './components/orderTable/OrderInfoSlideout';
import OrdersPageActions from './components/orderTable/OrdersPageActions';
import { useSearchParams } from 'react-router-dom';
import OrdersApi from '../../api/ordersApi';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import OrdersTopActions from './components/orderTable/OrdersTopActions';

const OrdersPage = () => {
  const auth = useSelector(selectAuth);
  const axios = useAxiosPrivate();
  const [searchParams] = useSearchParams();

  const isArtycViewer = isArtyc(auth);

  const [showCreate, setShowCreate] = useState(false);

  const initialOpenOrderId = searchParams.get('orderId');
  const [initialOpenOrder, setInitialOpenOrder] = useState<
    OrderEntity | undefined
  >(undefined);

  const loadOrder = async () => {
    if (initialOpenOrderId !== null) {
      try {
        const order = await OrdersApi.getOrder(axios, auth, initialOpenOrderId);
        setInitialOpenOrder(order);
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    loadOrder();
  }, [initialOpenOrderId]);

  const topConfig: TopConfig = {
    additionalBars: [<OrdersTopActions key="top-actions" />],
  };
  const emptyConfig: EmptyConfig = {
    icon: IconShipment,
    title: 'No orders found',
    body: 'Once orders are created, they will show up here',
  };

  // TODO(test): test that company only shows for artyc admin, date is startTime + format
  const columnsConfig: ColumnsConfig<OrderEntity> = {
    columns: [
      {
        title: 'Order ID',
        property: 'pid',
      },
      {
        title: 'Status',
        property: 'status',
        customComponent: (data, order) => {
          return <OrderStatusBadge status={order.status} />;
        },
      },
      {
        title: 'Contact',
        property: 'placedByUserName',
        showColumn: isArtycViewer,
      },
      {
        title: 'Company',
        property: 'companyName',
        showColumn: isArtycViewer,
      },
      {
        title: 'Submission Date',
        property: 'createdAt',
        customData: (order: OrderEntity) => {
          return `${formatDateTime(order.createdAt)}`;
        },
      },
      {
        title: 'Delivery Date',
        property: 'expectedDeliveryDate',
        customData: (order: OrderEntity) => {
          if (order.expectedDeliveryDate) {
            return `${formatDate(order.expectedDeliveryDate)}`;
          }
          return '-';
        },
      },
      {
        title: 'Fulfillment Date',
        property: 'fulfilledAt',
        customData: (order: OrderEntity) => {
          if (order.fulfilledAt) {
            return `${formatDateTime(order.fulfilledAt)}`;
          }
          return '-';
        },
      },
      {
        title: 'PO Number',
        property: 'poNumber',
      },
      {
        title: 'Custom ID',
        property: 'customId',
        showColumn: !isArtycViewer,
      },
    ],
    actionColumn: (order: OrderEntity) => (
      <OrderTableAction order={order} isArtycViewer={isArtycViewer} />
    ),
  };

  const slideoutConfig: SlideoutConfig<OrderEntity> = {
    slideout: (order, open, closeSlideout) => (
      <OrderInfoSlideout
        order={order}
        open={order ? open : false}
        closeSlideout={() => {
          closeSlideout();
          setInitialOpenOrder(undefined);
        }}
        isArtycViewer={isArtycViewer}
      />
    ),
    initialOpenEntity: initialOpenOrder,
  };

  return (
    <>
      <PageHeader
        headingText="Orders"
        action={
          <OrdersPageActions openCreateSlideout={() => setShowCreate(true)} />
        }
      />
      <DataTableContainer
        key="orders"
        topConfig={topConfig}
        emptyConfig={emptyConfig}
        columnsConfig={columnsConfig}
        slideoutConfig={slideoutConfig}
        stateName={'orders'}
        loadDataAction={loadOrders}
        sortAction={sortOrders}
        pageAction={setCurrentPage}
        resetAction={resetPage}
      />
      <CaasOrderFormSlideout
        open={showCreate}
        closeSlideout={() => setShowCreate(false)}
        isArtycViewer={isArtycViewer}
      />
    </>
  );
};

export default OrdersPage;
