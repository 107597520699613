import React from 'react';
import { useDispatch } from 'react-redux';
import TableSearch from '../../../../aurora/components/TableSearch/TableSearch';
import { setSearchFilter } from '../../../../state/orders';

const OrderSearch = () => {
  const dispatch = useDispatch();

  return (
    <TableSearch
      placeholder="Search by order ID, custom ID, or PO number"
      onChange={(text) => {
        dispatch(setSearchFilter(text));
      }}
    />
  );
};

export default OrderSearch;
