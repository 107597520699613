import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconWarningCircleFill: React.FunctionComponent<IconProps> = ({
  ...props
}) => (
  <IconBase {...props} viewBox="0 0 21 20">
    <Path
      d="M10.5 17.5C6.35775 17.5 3 14.1422 3 10C3 5.85775 6.35775 2.5 10.5 2.5C14.6422 2.5 18 5.85775 18 10C18 14.1422 14.6422 17.5 10.5 17.5ZM9.75 12.25V13.75H11.25V12.25H9.75ZM9.75 6.25V10.75H11.25V6.25H9.75Z"
      fill="currentColor"
    />
  </IconBase>
);
