import React from 'react';
import styles from './FleetViewMapClusterPopup.module.scss';

interface FleetViewMapClusterPopupProps {
  properties: {
    serialNumbers: string[];
  };
}

const FleetViewMapClusterPopup = ({
  properties,
}: FleetViewMapClusterPopupProps) => {
  return (
    <div className={styles.popup}>
      <div className={styles.title}>Shippers</div>
      {properties.serialNumbers.map((serialNumber) => (
        <div
          className={styles.serialNumber}
          key={serialNumber}
        >{`#${serialNumber}`}</div>
      ))}
    </div>
  );
};

export default FleetViewMapClusterPopup;
