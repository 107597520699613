import React, { forwardRef, useEffect, useState, useRef } from 'react';
import mapboxgl, { LayerSpecification, Map as MapboxMap } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { initializeMap, setupMap, updateMap } from './mapSetup';
import styles from './Map.module.scss';
import { GeoJsonProperties } from 'geojson';
import { CirclePaint } from 'mapbox-gl';

export interface MapPoint {
  latitude: number;
  longitude: number;
  properties: GeoJsonProperties;
}

export interface MapPointGroup {
  groupId: string;
  geometry: 'Point' | 'LineString';
  points: MapPoint[];
  groupStyle: LayerSpecification;
  onHover?: {
    popupComponent?: {
      component: React.ComponentType<any>;
      componentProps: (properties: GeoJsonProperties) => any;
    };
    paint?: {
      // what the paint style should be when hovered
      hoverPaint: CirclePaint;
      // what the paint style should be when not hovered, probably same as what's in groupStyle
      // onHover doesn't have access to the groupStyle, so we need to set it here
      defaultPaint: CirclePaint;
      // the mapbox case condition for which points in the layer should be painted with hover style
      conditionalPaintCase?: (featureProperties: GeoJsonProperties) => any;
    };
  };
  onClick?: (properties: GeoJsonProperties) => void;
}

export interface MapAsset {
  name: string;
  path: string;
}

export interface MapProps {
  assets?: MapAsset[];
  pointGroups: MapPointGroup[];
  onMapLoad?: (map: MapboxMap) => void;
  className?: string;
}

mapboxgl.accessToken = process.env.REACT_APP_MAP_TOKEN;

const Map = forwardRef(function Map(
  { pointGroups, assets, onMapLoad, className }: MapProps,
  mapContainerRef: React.ForwardedRef<HTMLDivElement>
) {
  const [error, setError] = useState<string | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const mapRef = useRef<MapboxMap | null>(null);
  const popupRef = useRef<mapboxgl.Popup | null>(null);

  useEffect(() => {
    if (!mapContainerRef || !('current' in mapContainerRef)) {
      return;
    }

    if (mapContainerRef.current) {
      const { map, popup } = initializeMap(mapContainerRef.current);
      mapRef.current = map;
      popupRef.current = popup;

      map.on('load', () => {
        setMapLoaded(true);
        setupMap(map, pointGroups, popup, assets);
        if (onMapLoad) {
          onMapLoad(map);
        }
      });

      map.on('error', (e) => {
        setError(`Map error: ${e.error?.message || 'Unknown error'}`);
      });

      return () => {
        map.remove();
      };
    }
  }, [onMapLoad, assets]);

  useEffect(() => {
    if (!mapLoaded || mapRef.current === null || popupRef.current === null) {
      return;
    }

    updateMap(mapRef.current, pointGroups, popupRef.current);
  }, [pointGroups, mapLoaded]);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={styles.mapContainer}>
      <div
        ref={mapContainerRef}
        className={`${styles.map} ${className || ''}`}
      ></div>
    </div>
  );
});

Map.displayName = 'Map';

export default React.memo(Map);
