import React from 'react';
import { Path } from 'react-native-svg';

import { IconBase, IconProps } from './IconBase';

export const IconSend: React.FunctionComponent<IconProps> = ({ ...props }) => (
  <IconBase viewBox="0 0 20 20" {...props}>
    <Path
      d="M3.27163 7.8878C2.91004 7.76711 2.90657 7.57221 3.27856 7.44806L16.5002 3.03536C16.8667 2.91328 17.0766 3.11859 16.974 3.47787L13.196 16.716C13.0921 17.0829 12.8808 17.0954 12.7257 16.7472L10.2361 11.1373L14.3923 5.58851L8.85068 9.75013L3.27163 7.8878Z"
      fill="currentColor"
    />
  </IconBase>
);
