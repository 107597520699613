import { AxiosInstance, AxiosResponse } from 'axios';
import { patch, post, del, get } from '../hooks/useAxiosPrivate';
import { genericFetch } from './api';
import { Auth } from '../state/auth/types';
import { DeviceEntity, DeviceType, EnvEnum } from '../state/devices/types';
import { DataPayload } from '../state/types';
import { FleetViewDevice } from '../state/fleetView/types';
import { isArtyc } from '../state/auth';

interface CreateDeviceBody {
  deviceType: DeviceType;
  serialNumber: string;
  pcbNumber: string;
  password: string;
  companyId: string;
}

const ARTYC_DEVICE_URL = '/artyc/devices';
const getCompanyDeviceUrl = (companyId: string | undefined) =>
  `/api/v1/company/${companyId}/devices`;

const DevicesApi = {
  createDevice: async (axios: AxiosInstance, data: CreateDeviceBody) => {
    const response: AxiosResponse<{ success: string }> = await post(
      axios,
      ARTYC_DEVICE_URL,
      data
    );
    return response.data;
  },
  updateDevice: async (
    axios: AxiosInstance,
    deviceId: string,
    // TODO(ts): type
    data: any
  ) => {
    const response: AxiosResponse<{ success: string }> = await patch(
      axios,
      `${ARTYC_DEVICE_URL}/${deviceId}`,
      data
    );
    return response.data;
  },
  deleteDevice: async (axios: AxiosInstance, deviceId: string) => {
    const response: AxiosResponse<{ success: string }> = await del(
      axios,
      `${ARTYC_DEVICE_URL}/${deviceId}`
    );
    return response.data;
  },
  getDeviceTypes: async (axios: AxiosInstance, auth: Auth | null) => {
    const companyUrl = getCompanyDeviceUrl(auth?.companyId);
    const response: { deviceTypes: DeviceType[] } = await genericFetch(
      axios,
      auth,
      `${ARTYC_DEVICE_URL}/types`,
      `${companyUrl}/types`
    );
    return response;
  },
  switchEnvs: async (
    axios: AxiosInstance,
    deviceIds: string[],
    newEnvironment: EnvEnum
  ) => {
    const responses: AxiosResponse<{ success: string }> = await patch(
      axios,
      `${ARTYC_DEVICE_URL}/switch`,
      { deviceIds, newEnvironment }
    );
    return responses.data;
  },
  getArtycDevices: async (
    axios: AxiosInstance,
    auth: Auth | null,
    deviceType: DeviceType
  ) => {
    const response: AxiosResponse<DataPayload<DeviceEntity>> = await get(
      axios,
      `${ARTYC_DEVICE_URL}`,
      {
        paginate: 'false',
        filters: {
          companyIds: [auth?.companyId || ''],
        },
        deviceTypes: [deviceType],
      }
    );
    return response.data;
  },
  getFleetViewDevices: async (
    axios: AxiosInstance,
    auth: Auth | null,
    companyId: string | null
  ): Promise<FleetViewDevice[]> => {
    const companyUrl = getCompanyDeviceUrl(
      companyId !== null ? companyId : auth?.companyId
    );

    const isArtycUser = isArtyc(auth);

    const entityURL =
      isArtycUser && companyId === null
        ? `${ARTYC_DEVICE_URL}/fleet`
        : `${companyUrl}/fleet`;

    const response: AxiosResponse<FleetViewDevice[]> = await get(
      axios,
      entityURL
    );
    return response.data;
  },
};

export default DevicesApi;
