import React from 'react';
import Select, {
  SelectOptionType,
} from '../../../../aurora/components/Select/Select';
import { useDispatch, useSelector } from 'react-redux';
import { setStatusFilter } from '../../../../state/orders';
import { OrderStatus } from '../../../../state/orders/types';
import { formatEnumToReadableText } from '../../../../utils/formatEnumToReadableText';
import { RootState } from '../../../../state/store';

const OrderStatusSelect = () => {
  const { selectedFilters } = useSelector((state: RootState) => state.orders);
  const dispatch = useDispatch();
  const statusOptions: SelectOptionType[] = Object.values(OrderStatus).map(
    (status) => ({
      value: status,
      label: formatEnumToReadableText(status),
    })
  );

  const onChange = (newValue: string | null) => {
    if (newValue == null) {
      dispatch(setStatusFilter(undefined));
    } else {
      dispatch(setStatusFilter([newValue as OrderStatus]));
    }
  };

  return (
    <Select
      placeholder="Select Status"
      options={statusOptions}
      onChange={(option: SelectOptionType) =>
        onChange(option?.value?.toString() || null)
      }
      variant="filter"
      managedValue={selectedFilters?.status?.[0] || ''}
      clearable
    />
  );
};

export default OrderStatusSelect;
