import React from 'react';
import ModalAction from '../../../../components/table/ModalAction';
import { IconSend } from '../../../../aurora/icons';
import { loadOrders } from '../../../../state/orders';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import {
  fulfillFormToBody,
  FulfillOrderFormData,
} from '../orderForm/mapOrderData';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../../state/auth';
import { OrderEntity } from '../../../../state/orders/types';
import OrdersApi from '../../../../api/ordersApi';
import FulfillOrderFormModal from '../orderForm/FulfillOrderFormModal';

interface Props {
  order: OrderEntity;
  isArtycViewer: boolean;
}
const FulfillOrderButton = ({ order, isArtycViewer }: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useSelector(selectAuth);

  if (!isArtycViewer) {
    return null;
  }

  return (
    <ModalAction
      label="Fulfill Order"
      modalTitle="Fulfill Order"
      icon={IconSend}
      stateName="orders"
      modalContent={(onDataChange, onClose) => (
        <FulfillOrderFormModal
          order={order}
          isArtycViewer={isArtycViewer}
          onSubmit={async (data: FulfillOrderFormData) => {
            const body = fulfillFormToBody(data);
            await OrdersApi.updateOrder(axiosPrivate, auth, order._id, body);
            await onDataChange();
          }}
          onClose={onClose}
          fulfilling={true}
        />
      )}
      loadDataAction={loadOrders}
      modalWidth="446px"
    />
  );
};

export default FulfillOrderButton;
