import React from 'react';
import DateRangeCalendar, {
  DateRangeType,
} from '../../../aurora/components/CalendarPicker/DateRangeCalendar';
import MobileFilter, {
  SlideoutContentProps,
} from '../../../aurora/components/MobileFilter/MobileFilter';
import { formatDateMonthDay } from '../../../utils/dateUtil';
import styles from './MobileDateRangePicker.module.scss';

interface Props {
  maxDate?: Date;
  currentDateRange: DateRangeType;
  onApplyRange: (range: DateRangeType) => void;
  onClearRange: () => void;
  placeholder?: string;
}

const MobileDateRangePicker = ({
  maxDate,
  currentDateRange,
  onApplyRange,
  onClearRange,
  placeholder,
}: Props) => {
  const content = ({ onSetFilters }: SlideoutContentProps<DateRangeType>) => {
    return (
      <div className={styles.container}>
        <DateRangeCalendar
          initialRange={currentDateRange}
          maxDate={maxDate}
          onSetRange={onSetFilters}
          excludeButtons
        />
      </div>
    );
  };

  return (
    <MobileFilter
      emptyFilters={[undefined, undefined]}
      appliedFilters={currentDateRange}
      placeholder={placeholder || 'Select Dates'}
      slideoutContent={content}
      onApplyFilters={onApplyRange}
      onClear={onClearRange}
      toFiltersSummary={(range: DateRangeType) =>
        `${formatDateMonthDay(range[0])} - ${formatDateMonthDay(range[1])}`
      }
    />
  );
};

export default MobileDateRangePicker;
