import React from 'react';
import { Heading } from '../../../../aurora/typography/Heading/Heading';
import { Text } from '../../../../aurora/typography/Text/Text';
import { JourneyEntity } from '../../../../state/journeys/types';
import { ShipmentEntity } from '../../../../state/shipments/types';
import styles from './JourneyPageContainer.module.scss';
import CarrierTrackingCard from './leftColumn/CarrierTrackingCard';
import OrderInfoCard from './leftColumn/OrderInfoCard';
import ShipmentDetailsCard from './leftColumn/ShipmentDetailsCard';
import ShipperInfoCard from './leftColumn/ShipperInfoCard';

interface Props {
  journey: JourneyEntity;
  shipment: ShipmentEntity;
  isArtycViewer: boolean;
}
const UnregulatedShipmentContainer = ({
  journey,
  shipment,
  isArtycViewer,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.leftColumn}>
        <div>
          <Heading size="h4" color="strong900">
            {shipment.pid}
          </Heading>
          {isArtycViewer ? (
            <Text size="xl" color="strong900">
              {journey.companyName}
            </Text>
          ) : null}
        </div>
        <OrderInfoCard journey={journey} />
        <ShipperInfoCard journey={journey} showOpen={true} />
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.cardRow}>
          <ShipmentDetailsCard shipment={shipment} />
          <CarrierTrackingCard shipment={shipment} />
        </div>
      </div>
    </div>
  );
};

export default UnregulatedShipmentContainer;
