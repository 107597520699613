import React from 'react';
import styles from './ProgressStepper.module.scss';
import { IconCircle, IconCheckCircleFill } from '../../icons';

interface Props {
  steps: string[];
  currentStepIndex: number;
}

const ProgressStepper = ({ steps, currentStepIndex }: Props) => {
  return (
    <div className={styles.stepProgress}>
      {steps.map((step, index) => {
        const isComplete = index < currentStepIndex;
        const isInProgress = index === currentStepIndex;
        const isLastStep = index === steps.length - 1;

        return (
          <div className={styles.step} key={index}>
            <div className={styles.stepProgress}>
              <div
                className={`${styles.stepIcon} ${
                  isComplete ? styles.complete : ''
                }`}
              >
                {isComplete ? (
                  <IconCheckCircleFill width={16} height={16} />
                ) : isInProgress ? (
                  <IconCircle width={12} height={12} />
                ) : (
                  <IconCircle width={12} height={12} />
                )}
              </div>
              {!isLastStep && (
                <div
                  className={`${styles.stepLine} ${
                    index + 1 < currentStepIndex ? styles.complete : ''
                  }`}
                />
              )}
            </div>
            <div
              className={`${styles.stepLabel} ${
                isComplete ? styles.complete : ''
              }`}
            >
              {step}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProgressStepper;
