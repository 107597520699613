import React from 'react';
import { useSelector } from 'react-redux';
import { isArtyc, isCompanyAdmin, selectAuth } from '../../../../state/auth';
import { Button } from '../../../../aurora/components/Button/legacy/Button';
import { IconPlus } from '../../../../aurora/icons';
import { selectIsCaas } from '../../../../state/companies';

interface Props {
  openCreateSlideout: () => void;
}
const OrdersPageActions = ({ openCreateSlideout }: Props) => {
  const auth = useSelector(selectAuth);
  const isCaas = useSelector(selectIsCaas);
  const canCreate = isArtyc(auth) || (isCompanyAdmin(auth) && isCaas);

  return (
    <div>
      {canCreate ? (
        <Button
          variant="primary"
          label="Create Order"
          iconLeft={IconPlus}
          onClick={openCreateSlideout}
        />
      ) : null}
    </div>
  );
};

export default OrdersPageActions;
